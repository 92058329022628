import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";

export const createContact = ({ data }) => {
  return axios.post(`/mailing`, data);
};

createContact.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
  }).isRequired,
};

export const useCreateContact = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newContact) => {
      await queryClient.cancelQueries("contacts");

      const previousContacts = queryClient.getQueryData("contacts");

      queryClient.setQueryData("contacts", [
        ...(previousContacts || []),
        newContact.data,
      ]);

      return { previousContacts };
    },
    onError: (err) => {
      console.log(err);
      addNotification({
        type: "error",
        message: err.response.data.msg,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("contacts");
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: createContact,
  });
};
