import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getCareersDetails = () => {
  return axios.get(`/pages/slug/careers`);
};

export const useCareersDetails = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["careersDetails"],
    queryFn: () => getCareersDetails(),
  });
};
