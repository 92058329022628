import PropTypes from "prop-types";
import LogoImage from "assets/images/logo512.png";

export const Spinner = () => {
  return (
    <>
      <div className="flex flex-row items-center h-[100vh]">
        <img
          src={LogoImage}
          alt="Loading"
          className="h-32 w-auto mx-auto animate__animated animate__flipInX animate-bounce"
        />
      </div>
    </>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
};
