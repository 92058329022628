import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import EnidAvatar from "../../../assets/profiles/Nyendwoha Enid Joy 2.jpg";

export const EarlyChildhoodCare = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Early Childhood care and Education"
        to="/management"
        parent="management"
      />
      <div className="mx-8 lg:mx-20 my-12 px-2">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <div className="my-4 text-justify">
              <p className="my-4">
                Early childhood care and education describes the period of
                learning that takes place from birth to 8 years old. Early
                Childhood Education is a term that refers to educational
                programs and strategies geared toward children from birth to the
                age of eight. Early childhood education often focuses on guiding
                children to learn through play.
              </p>
              <p className="text-xl my-4">
                The Aims and Objectives of Early Childhood Care and Education
              </p>
              <p className="my-4">
                According to the “Education for National Integration and
                Development”, (Government White Paper on The Education Policy
                Review Commission Report – 1992), the aims and Objectives of
                pre-primary education are under listed:
              </p>
              <ul className="list-disc px-4">
                <li>
                  To develop capabilities and healthy physical growth of the
                  child through play/ activities.
                </li>
                <li>
                  To help the child develop good social habits as an individual
                  and as a member of society.
                </li>
                <li>To develop moral values in the child.</li>
                <li>
                  To receive work from the academic departments and evaluate
                  make recommendations on how to improve it.
                </li>
                <li>
                  To enrich the child’s experience by developing imagination,
                  self-reliance and thinking power.
                </li>
                <li>
                  To help the child towards appreciating his/her national
                  cultural background and customs, and developing a feeling of
                  love and care for other people and for Uganda and a sense of
                  unity leading to a national stance.
                </li>
                <li>
                  To develop language and communication skills in the mother
                  tongue.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={EnidAvatar}
              alt="Deborah"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Deborah Magera</p>
            <p className="">Acting Manager Primary Education</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
