import { useState, useEffect } from "react";
import Masonry from "react-masonry-css";
import { X } from "react-feather";
import { MainLayout, PageHeader } from "components/layout";
import Lazyimage from "assets/images/lazyimage.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useGallery } from "../api/getGallery";
import { UPLOADS_URL } from "config";
import { Spinner } from "components/elements";
import { useGalleryDetails } from "../api/getGalleryDetails";

export const Gallery = () => {
  // Get Gallery images from api
  const galleryImage = useGallery();
  // console.log(galleryImage);

  const detailsQuery = useGalleryDetails();

  // scroll to the top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const breakpoints = {
    default: 3,
    1100: 2,
    700: 1,
  };

  //State for storing image url and caption
  const [modal, setModal] = useState("");
  const [modal_details, setModal_details] = useState("");

  console.log(typeof modal);

  return (
    <MainLayout>
      <PageHeader
        header={detailsQuery.data?.title || "Undefined"}
        desc={detailsQuery.data?.desc}
        backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
      />
      <div className="relative">
        <Masonry
          breakpointCols={breakpoints}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {!galleryImage.data ? (
            <Spinner />
          ) : (
            galleryImage.data.map((images, index) => {
              return (
                <div key={index} className="relative w-full">
                  <div
                    onClick={() => {
                      setModal(`${UPLOADS_URL}/${images.image}`);
                      setModal_details(images.caption);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalCenter"
                    data-backdrop="static"
                    data-keyboard="false"
                    className="px-2 md:px-0 bg-white"
                  >
                    <LazyLoadImage
                      src={`${UPLOADS_URL}/${images.image}`}
                      alt="gallery"
                      className="block w-full rounded"
                      placeholderSrc={Lazyimage}
                    />
                    <div className=" absolute bottom-4 md:bottom-12 left-1/2 transform -translate-x-1/2 filter drop-shadow-2xl text-white text-center">
                      <h5 className="text-xl">{images.caption}</h5>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </Masonry>
        <div className={modal ? "flex" : "hidden"}>
          <div
            className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
            id="exampleModalCenter"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered relative w-auto pointer-events-none">
              <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <button
                  className="flex flex-row-reverse py-2 px-4"
                  onClick={() => setModal(false)}
                  data-bs-dismiss="modal"
                >
                  <X />
                </button>
                <div className="modal-body relative p-4">
                  <img src={modal} alt="..." className="w-full" />
                  <p className="text-gray-800 font-semibold my-2">
                    {modal_details}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
