import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import FemaleAvatar from "assets/images/female-avatar.jpeg";

export const LiteratureBureau = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Literature Bureau"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12 px-2">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3">
            <div className="my-2">
              <p className="my-2 font-semibold">What it is</p>
              <p>
                Literature Bureau is a platform for nurturing local authorship
                by promoting free - writing, community based or bottom – up
                approach to literary production.
              </p>
            </div>
            <div className="my-2">
              <p className="font-semibold">Main Purpose</p>
              <p>Its main purpose is to;</p>
              <ul className="list-disc">
                <li>
                  Meet increasing demands for reading and instructional
                  materials of all kinds
                </li>
                <li>Promote local authorship</li>
                <li>Provide publishing opportunities</li>
                <li>
                  Promote creativity and diversity in writing b encouraging
                  authors to write both general and subject specific literature
                </li>
              </ul>
            </div>
            <div className="my-2">
              <p className="font-semibold">
                Activities/Services (Not limited to these)
              </p>
              <ul className="list-disc">
                <li>
                  Develop guidelines for writing literary works and the
                  evaluation criteria
                </li>
                <li>Develop guidelines for translation</li>
                <li>Develop guidelines for terminology development</li>
                <li>Provide translation services</li>
                <li>
                  Train prospective writers to participate in the writing
                  activities at different levels
                </li>
                <li>Evaluating literary works produced by authors</li>
                <li>Updating local language orthographies</li>
                <li>
                  Renewal of District language Board and training them on how to
                  write materials
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2 mx-auto text-center">
            <img
              src={FemaleAvatar}
              alt="dr grace k baguma"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="mt-2">Name</p>
            <p className=""></p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
