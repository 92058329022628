import { Form, InputField, DefaultTextareaField } from "components/Form";
import * as z from "zod";

import { useCreateMessage } from "../api/sendMessage";
import { Send } from "react-feather";

const schema = z.object({
  email: z.string().min(1, "Required"),
  subject: z.string().min(1, "Required"),
  content: z.string().min(1, "Required"),
});

export const CreateMessage = () => {
  const CreateMessageMutation = useCreateMessage();

  return (
    <>
      <Form
        id="create-message"
        onSubmit={async (values) => {
          await CreateMessageMutation.mutateAsync({ data: values });
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              className="rounded-md bg-white"
              placeholder="Email Address"
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <InputField
              className="rounded-md bg-white"
              placeholder="Subject"
              error={formState.errors["subject"]}
              registration={register("subject")}
            />
            <DefaultTextareaField
              className="rounded-md bg-white"
              placeholder="Message..."
              error={formState.errors["content"]}
              registration={register("content")}
            />
            <div className="mb-2">
              <button
                type="submit"
                className="mt-3 flex flex-row space-x-2 items-center px-6 py-2.5  text-white font-medium text-xs leading-tight uppercase rounded-md shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-200 focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
                style={{ backgroundColor: "#008e51" }}
              >
                <Send /> <span>Send Message</span>
              </button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};
