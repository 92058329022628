import { useState } from "react";

export default function useCart() {
  const [cartItems, setCartItems] = useState([]);

  function addToCart(resource) {
    setCartItems((prev) => {
      const existing = cartItems.find((item) => item.id === resource._id);

      return existing
        ? [
            ...cartItems.map((item) =>
              item.id === resource._id ? { ...item, qty: item.qty + 1 } : item
            ),
          ]
        : [...prev, { ...resource, qty: 1 }];
    });
  }

  function removeFromCart(resource) {
    setCartItems((prev) => [
      ...prev.filter((item) => item.id !== resource._id),
    ]);
  }

  function changeQty(resource, qty) {
    if (qty === 0) return removeFromCart(resource);

    setCartItems((prev) => [
      ...prev.map((item) =>
        item.id === resource._id ? { ...item, qty } : item
      ),
    ]);
  }

  return {
    cartItems,
    addToCart,
    removeFromCart,
    changeQty,
  };
}
