import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { MDPreview, Spinner } from "../../../components/elements";
import { MainLayout, PageHeader } from "../../../components/layout";
import { useJob } from "../api/getJob";
import { ApplicationForm } from "../components/ApplicationForm";

export function Career() {
  const { jobId } = useParams();

  const jobQuery = useJob({ jobId });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (jobQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (!jobQuery.data) return null;

  return (
    <MainLayout page="opportunity">
      <PageHeader header={jobQuery.data.title} to="/careers" parent="Careers" />
      <div className="my-12 mx-48">
        <p className="py-4 text-2xl font-semibold">{jobQuery.data.title}</p>
        <MDPreview value={jobQuery.data.desc} />
        <button
          className="text-gray-100 p-3 rounded-full bg-green-600 transition-all duration-500 hover:bg-green-700"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalCenter"
        >
          Apply Now
        </button>
        <div
          class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id="exampleModalCenter"
          tabindex="-1"
          aria-labelledby="exampleModalCenterTitle"
          aria-modal="true"
          role="dialog"
        >
          <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalScrollableLabel"
                >
                  Apply for job.
                </h5>
                <button
                  type="button"
                  class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body relative p-4">
                <ApplicationForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
