import FemaleAvatar from "../../../assets/images/female-avatar.jpeg";
import MaleAvatar from "../../../assets/images/man-avatar.jpeg";
import SiimaAvatar from "../../../assets/profiles/Gilbert Siima 2.jpg";
import FredAvatar from "assets/profiles/Fred Galiwango 1.jpg";

export const ResearchTeam = () => {
  return (
    <>
      <div className="grid grid-row md:grid-cols-6 pt-4">
        <div className="flex flex-col">
          <div className="text-center">
            <div className="">
              <img
                src={MaleAvatar}
                alt="Dr. Grace K. Baguma"
                className="rounded-full mx-auto h-28 w-auto my-2 border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-bold text-xl hidden">undefined</p>
              <p>Manager Research & Consultancy</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-center">
            <div className="">
              <img
                src={FemaleAvatar}
                alt="Irene"
                className="rounded-full mx-auto h-28 w-auto my-2 border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-bold text-xl">Irene Nakayenya</p>
              <p>Acting Manager Documentation & Library Services</p>
            </div>
          </div>
        </div>
        <div className="grid grid-col">
          <div className="text-center">
            <div className="">
              <img
                src={FemaleAvatar}
                alt="Dr. Grace K. Baguma"
                className="rounded-full mx-auto h-28 w-auto my-2 border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-bold text-xl">Phillip Oketcho</p>
              <p>Manager Literature Bureau</p>
            </div>
          </div>
        </div>
        <div className="grid grid-col">
          <div className="text-center">
            <div className="">
              <img
                src={SiimaAvatar}
                alt="Manager ICT and Multimedia"
                className="rounded-full mx-auto h-28 w-auto my-2 border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-semibold text-xl">Siima Gilbert Gift</p>
              <p>Manager ICT & Multi Media</p>
            </div>
          </div>
        </div>
        <div className="grid grid-col">
          <div className="text-center">
            <div className="">
              <img
                src={FredAvatar}
                alt="Fred"
                className="rounded-full mx-auto h-28 w-auto my-2 border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-bold text-xl">Fred Galiwango</p>
              <p>Manager Science and Technology Equipment Production Unit</p>
            </div>
          </div>
        </div>
        <div className="grid grid-col">
          <div className="text-center">
            <div className="">
              <img
                src={FemaleAvatar}
                alt="Dr. Grace K. Baguma"
                className="rounded-full mx-auto h-28 w-auto my-2 border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-bold text-xl hidden">Undefined</p>
              <p>Manager Print & Production</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
