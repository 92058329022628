import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import KwiriAvatar from "../../../assets/profiles/Kwiri Stephen 2.jpg";

export const Pedagogy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Pedagogy and Study Practice"
        to="/management"
        parent="Management"
      />

      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <div className="m-2 text-justify">
              <p className="my-2 font-semibold">Who are we?</p>
              <p>
                Pedagogy and Innovation is a Service Unit that supports the
                mandate of NCDC by Working hand in hand with existing
                departments: Early Childhood Development (ECD), Primary,
                Secondary, Life-long learning skills and Special Needs in
                ensuring a comprehensive teaching and learning process across
                all levels.
              </p>
              <p className="my-2 font-semibold">What do we do?</p>
              <ul className="list-disc">
                <li>
                  Initiate Pedagogical Solutions in the Curriculum across
                  levels.
                </li>
                <li>
                  Support existing departments in incorporating pedagogical
                  solutions in the developed.
                </li>
                <li>Syllabi, books, teaching and learning guides.</li>
                <li>
                  Develop Bulletins, Manuals with methods that equip learners
                  with the 21 st Century Skills.
                </li>
                <li>
                  Support the existing departments in training teachers to
                  interpret the methodologies in the curriculum and other
                  support materials.
                </li>
                <li>
                  Partner with other institutions and organizations in fostering
                  the teaching and learning process through innovative
                  methodologies and materials.
                </li>
              </ul>
              <p className="my-2 font-semibold">What are our Activities?</p>
              <ul className="list-disc">
                <li>Research relevant pedagogies.</li>
                <li>
                  Integrating relevant Pedagogical Theories into the Curriculum.
                </li>
                <li>Develop Bulletins, Manuals, Guides and Resource Books.</li>
                <li>
                  Organize workshops on Pedagogy (Andragogy) and Innovation.
                </li>
                <li>
                  Training Subject specialists in Pedagogies of the 21st
                  Century.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={KwiriAvatar}
              alt="Kwiri Stephen"
              className="h-60 w-60 rounded-full border-4 border-green-400 mx-auto"
              loading="lazy"
            />
            <p className="text-2xl mt-2 text-center">Kwiri Stephen</p>
            <p className=" text-center">Pedagogy and study practice</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
