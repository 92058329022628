import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { MainLayout, PageHeader } from "components/layout";
import { useAd } from "../api/getAd";
import { useAds } from "../api/getAds";
import { MDPreview, Spinner, Text } from "components/elements";
import { UPLOADS_URL } from "config";
import formatDate from "utils/formatDate";
import { ArrowRight } from "react-feather";

export const Advert = () => {
  const { slug } = useParams();

  const adQuery = useAd({ slug });

  const adsQuery = useAds();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (adQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  // console.log(adQuery.data);

  if (!adQuery.data) return null;

  return (
    <MainLayout>
      <PageHeader
        header={adQuery.data.title}
        // to="/"
        // parent="Adverts"
        backgroundImage={`${UPLOADS_URL}/${adQuery.data.image}`}
      />

      <div className="mx-4 lg:mx-20 my-2">
        <div className="grid grid-cols-1 gap-8">
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <MDPreview value={adQuery.data.desc} />
              <div className="w-3/4">
                <p className="text-xl font-semibold text-green-700 my-2 uppercase">
                  Latest Advertisements
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {adsQuery.data.slice(0, 3).map((ad, index) => {
                    return (
                      <div className="flex flex-col">
                        <div className="flex flex-col">
                          <img
                            src={`${UPLOADS_URL}/${ad.image}`}
                            alt={ad.title}
                            className="w-full h-64 object-cover border border-gray-200"
                          />
                          <div className="flex flex-col mt-2 text-green-700 font-bold">
                            <p className="text-md">{ad.title}</p>
                          </div>
                          {/* <div
                            className="text-gray-400"
                            style={{
                              fontSize: "0.6rem",
                            }}
                          >
                            Posted by Webmaster .{" "}
                            {formatDate(adQuery.data.createdAt)}{" "}
                          </div> */}
                        </div>
                        <div className="my-2">
                          <Link to={`/adverts/${ad.slug}`}>
                            <button className="text-white bg-green-600 p-2 px-4 flex flex-row items-center hover:bg-green-800">
                              <p className="text-md">Read more</p> &nbsp;{" "}
                              <ArrowRight className="h-4" />
                            </button>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="m-2">
              {/* <Text size="xs">
                Posted on: {formatDate(adQuery.data.createdAt)}
              </Text> */}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
