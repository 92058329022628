import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import FemaleAvatar from "assets/images/female-avatar.jpeg";

export const PrimaryDepartment = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Primary Department"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3">
            <div className="m-2">
              The Pre-Primary and Primary department has two sections; the
              Pre-Primary Section and the Primary section. The Pre-Primary
              section handles curriculum issues concerning pre-school and is
              categorized in two age groups of 0-3 years and 3-6 years, while
              Primary covers P1 to P7.
              <br />
              <br />
              The department is charged with the responsibility of development,
              interpretation and reviewing of inclusive curricula and
              instructional support materials, providing teacher support
              programmes and providing guidance on assessment at pre-Primary and
              Primary education level.
              <br />
              <br />
              Our Main activities for the financial year 2017/18 were;
              Transcribing the P4 and P5 curriculum into Braille; Formation of
              nine language boards for development of orthographies; Development
              of P5-7 Kiswahili orientation manuals; Development of manual for
              making ECD inclusive playgrounds and play materials; Production of
              Religious Education supplementary readers and charts; Reviewing
              the Nile English course book, Teacher’s Guide and PACE; Record and
              produce an audio copy of the songbook; Development of a resource
              book for Global Citizenship Education with the support from APCIU
              and LABE, we have developed Complementary Learning Framework for
              ECCE 3-5yrs and 5-6yrs.
            </div>
            {/* <div className="m-2">
              <p className="my-4 text-justify">
                The Primary department manages the curriculum from primary four
                to primary seven. The age group for these learners is 9- 13
                years. The lower primary curriculum is now under ECD whose age
                group is 0-8 years.
              </p>
              <p className="my-4 text-justify">
                The Primary department is charged with the responsibility of
                reviewing, developing, and interpreting of inclusive curricula
                and instructional support materials, providing teacher support
                programmes, providing guidance on assessment at the Primary
                cycle (P.4 to P.7) and evaluating materials that support the
                implementation of the Curriculum.
              </p>
              <p className="my-4 text-justify">
                The department is currently headed by Enid Joy Nyendwoha, the
                Curriculum Specialist for Physical Education as the manager. The
                department comprise the following subject specialists; Patrice
                Ssembirige (social Studies), Gertrude Namubiru - PhD
                (Mathematics), Elly Musana Wairagala (English), Cale Santus
                (Integrated Science), Abdul Aziz Kakooza (Religious Studies),
                Jonathan Mugenyi (Music, Dance and Drama), Maureen Nampeera
                (Local language), Arinaitwe Perpetua (Kisawhili) and Catherine
                Neumbe (Administrator). The Music Dance and Drama, Physical
                Education and Art & Technology are also referred to as CAPEs.
              </p>
              <p className="ml-4">
                Music Dance and Drama : CAPE 1<br />
                Physical Education : CAPE 2<br />
                Art and Technology : CAPE 3<br />
              </p>
              <p className="font-bold">
                Our Main activities for the financial year 2021/2022 were;
              </p>
              <p className="my-4">
                In order to promote a culture of quality control and management
                at the Centre execute the following are the key activities of
                the department:
              </p>
              <ul className="list-disc">
                <li>
                  Designing the Abridged Curriculum for Primary and orienting
                  teachers nationwide.
                </li>
                <li>
                  Updating Kiswahili Curricular and orienting Master trainers
                </li>
                <li>Evaluating other Curriculum materials</li>
                <li>
                  Giving support to sister institutions and capacity building of
                  teachers.
                </li>
              </ul>
              <p className="my-4">
                The department oriented Master trainers in Abridged Curriculum
                and the workshop took place at Kololo Secondary School. Training
                of teachers was conducted via zoom in the four regions; Western,
                Central, Northern and Eastern.
              </p>
              <p className="my-4">
                The department has also been able to orient teachers in
                Kalangala district physically. The workshop lasted for 4 days
                and all Curriculum Specialists attended in person.
              </p>
              <p className="my-4">
                The department will also be going to Karamoja region in April
                2022 to orient the teachers. The master trainers will be
                involved in the orientation.
              </p>
            </div> */}
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={FemaleAvatar}
              alt="dr grace k baguma"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Patrice Sembirige</p>
            <p className="">Manager Primary Department</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
