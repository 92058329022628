import { axios } from '../../../lib/axios';
import { useQuery } from 'react-query';

export const getJob = ({ jobId }) => {
  return axios.get(`/jobs/${jobId}`);
};

export const useJob = ({ jobId, config }) => {
  return useQuery({
    ...config,
    queryKey: ['job', jobId],
    queryFn: () => getJob({ jobId }),
  });
};
