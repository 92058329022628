import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import NambiAvatar from "assets/profiles/Nambi R B 2.jpg";

export const CurriculumInstruction = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Curriculum & Instruction"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12 px-2">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 md:pt-10">
            <p className="my-4 text-justify">
              Controls, guides and implements the Centre’s research and
              consultancy programs.
            </p>
            <p className="my-4 text-justify">
              Coordinates the initiation, planning and development of a research
              and consultancy strategy for the organisation that contributes to
              the overall development of the Centre’s strategic plan.
            </p>
            <p className="my-4 text-justify">
              Coordinates research and consultancy projects and monitor contract
              performance to ensure that they deliver timely and cost-effective
              results that enhance the effectiveness of the Centre’s service
              delivery.
            </p>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={NambiAvatar}
              alt="Nambi R B 1"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2 text-center">
              Dr Bernadette N. Karuhanga
            </p>
            <p className=" text-center">
              Deputy Director Curriculum and Instructional Material Development
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
