import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import KwiriAvatar from "../../../assets/profiles/Kwiri Stephen 2.jpg";

export const ManagerAcademic = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Manager Academic"
        to="/management"
        parent="Management"
      />

      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3  text-justify">
            <p className="my-4 mx-2 text-gray-300">
              A word from Kwiri Stephen goes here.
            </p>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={KwiriAvatar}
              alt="Kwiri Stephen"
              className="h-60 w-60 rounded-full border-4 border-green-400 mx-auto"
              loading="lazy"
            />
            <p className="text-2xl mt-2 text-center">Kwiri Stephen</p>
            <p className=" text-center">Manager Academic</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
