/** @format */

import { useEffect, useState } from "react";
import { MainLayout, PageHeader } from "components/layout";
import { History } from "../components/History";
import { Areas } from "../components/Areas";
import { Functions } from "../components/Functions";
import { useAboutDetails } from "../api/getPageDetails";
import { UPLOADS_URL } from "config";

export function About() {
  const detailsQuery = useAboutDetails();

  const [isActive, setisActive] = useState("history");

  const changeComponent = (item) => {
    setisActive(item);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout page="about">
      <PageHeader
        header={detailsQuery.data?.title || "Undefined"}
        desc={detailsQuery.data?.desc}
        backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
      />
      <div className="my-2 mx-2 px-8 lg:px-20">
        <div className="grid grid-cols-0 gap-8 items-center w-fit h-fit bg-white shadow-md">
          <div className="p-3">
            <p className="font-semibold p-4">
              <span className="text-3xl">Who Are We</span>
            </p>
            <div className="text-justify p-4">
              <p className="text-md">
                The National Curriculum Development Centre (NCDC) is a corporate
                autonomous statutory institution under the Ministry of Education
                and Sports (MoE&S) responsible for the development of
                educational curricula for Pre-primary, Primary, Secondary and
                Tertiary institutions in Uganda. <br />
                Its functions include, inter alia, initiating processes for
                curricula research, review and/or reform, updating, testing and
                coordinating the implementation of educational curricula at all
                levels of learning (Pre-Primary, Primary, Secondary and
                Tertiary) as well as promulgation of guidance for the
                implementation of improved educational curricula and pedagogy.
              </p>
            </div>
          </div>
        </div>
        <div className="my-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div
              className="border border-gray-200 rounded-md text-center items-center 
            p-8 shadow-md shadow-gray-300 hover:shadow-xl hover:bg-green-100 hover:shadow-gray-300"
            >
              <p className="font-bold text-2xl my-4">
                Our{" "}
                <span className="" style={{ color: "#008e51" }}>
                  Vision
                </span>
              </p>
              <p className=" text-center text-md">
                {" "}
                A centre of excellence in curriculum development that is
                internationally recognized
              </p>
            </div>
            <div
              className="border border-gray-200 rounded-md text-center items-center 
            p-8 shadow-md shadow-gray-300 hover:shadow-xl hover:bg-green-100 hover:shadow-gray-300"
            >
              <p className="font-bold text-2xl my-4">
                Our{" "}
                <span className="" style={{ color: "#008e51" }}>
                  Mission
                </span>
              </p>
              <p className="text-center text-md">
                {" "}
                To develop quality, equitable curricula and instructional
                materials to meet societal needs through research, innovation
                and stakeholder involvement
              </p>
            </div>
            <div
              className="border border-gray-200 rounded-md text-center items-center 
            p-8 shadow-md shadow-gray-300 hover:shadow-xl hover:bg-green-100 hover:shadow-gray-300"
            >
              <p className="font-bold text-2xl my-4">
                Core{" "}
                <span className="" style={{ color: "#008e51" }}>
                  Values
                </span>
              </p>
              <div className="text-left  mx-8">
                <div className="mb-2">
                  <span className="text-md">T - Teamwork</span>
                </div>

                <div className="mb-2">
                  <p className="text-md">I - Integrity</p>
                </div>
                <div className="mb-2">
                  <p className="text-md">E - Equity</p>
                </div>
                <div className="mb-2">
                  <p className="text-md">E - Excellence</p>
                </div>
              </div>
            </div>
            <div
              className="border border-gray-200 rounded-md text-center items-center 
            p-8 shadow-md shadow-gray-300 hover:shadow-xl hover:bg-green-100 hover:shadow-gray-300"
            >
              <p className="font-bold text-2xl my-4">
                Overall{" "}
                <span className="" style={{ color: "#008e51" }}>
                  Goal
                </span>
              </p>
              <div className=" text-center">
                <p className="text-md">
                  To build a firm foundation for a centre of excellence in
                  curriculum and instructional materials development
                </p>
              </div>
            </div>
          </div>
          <div className="my-12">
            <div className=" border-b-4 border-gray-300">
              <div className="flex justify-around w-full md:w-[34vw]">
                <button
                  className={
                    isActive === "history"
                      ? "mx-2 px-2 w-40 text-gray-800 bg-gray-300 hover:bg-green-600 hover:text-white font-semibold"
                      : "mx-2"
                  }
                  onClick={() => changeComponent("history")}
                >
                  Our History
                </button>
                <button
                  className={
                    isActive === "areas"
                      ? "mx-2 px-2 text-gray-800 bg-gray-300 hover:bg-green-600 hover:text-white font-semibold"
                      : "mx-2"
                  }
                  onClick={() => changeComponent("areas")}
                >
                  Our Strategic Areas
                </button>
                <button
                  className={
                    isActive === "functions"
                      ? "mx-2 px-2 text-gray-800 bg-gray-300 hover:bg-green-600 hover:text-white font-semibold"
                      : "mx-2"
                  }
                  onClick={() => changeComponent("functions")}
                >
                  NCDC Functions
                </button>
              </div>
            </div>
            <div>
              {isActive === "history" && <History />}
              {isActive === "areas" && <Areas />}
              {isActive === "functions" && <Functions />}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
