import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import DirectorAvatar from "assets/profiles/Grace Baguma 1.jpg";
import MaleAvatar from "assets/images/man-avatar.jpeg";

export const OfficeOfDirector = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Office of the Director"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12 px-2">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mb-2">
          <div className="text-gray-500 col-span-3 text-justify">
            <p className="my-4">
              NCDC Management is headed by the Director, who is assisted by
              Deputy Directors.
            </p>
          </div>
          <div className="mx-auto col-span-2">
            <img
              src={DirectorAvatar}
              alt="dr grace k baguma"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
              loading="lazy"
            />
            <p className="text-2xl mt-2">Dr. Grace K. Baguma</p>
            <p className="">Executive Director, NCDC</p>
          </div>
        </div>
        <div className="grid grid-row md:grid-cols-3 mt-6">
          <div className="flex flex-col">
            <div className="text-center bg-white">
              <div className="">
                <img
                  src={MaleAvatar}
                  alt=""
                  className="rounded-full mx-auto h-28 w-auto my-2 border-2 border-green-500"
                />
              </div>
              <div className="py-2 mx-2">
                <p className="font-bold text-xl hidden">undefined</p>
                <p>
                  Deputy Director, Curriculum Review & Instructional Material
                  Development
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-center bg-white">
              <div className="">
                <img
                  src={MaleAvatar}
                  alt="Dr. Grace K. Baguma"
                  className="rounded-full mx-auto h-28 w-auto my-2 border-2 border-green-500"
                />
              </div>
              <div className="py-2 mx-2">
                <p className="font-bold text-xl hidden">undefined</p>
                <p>
                  Acting Deputy Director, Research Consultancy & Library
                  services
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-center bg-white">
              <div className="">
                <img
                  src={MaleAvatar}
                  alt="Dr. Grace K. Baguma"
                  className="rounded-full mx-auto h-28 w-auto my-2 border-2 border-green-500"
                />
              </div>
              <div className="py-2 mx-2">
                <p className="font-bold text-xl hidden">Undefined</p>
                <p>Deputy Director, Finance HR and Administration</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
