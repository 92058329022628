import React from "react";
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
import DefaultBackgroundImage from "assets/images/pageheader__background.png";

export const PageHeader = ({ header, desc, to, parent, backgroundImage }) => {
  return (
    <>
      <div
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url("${
            backgroundImage || DefaultBackgroundImage
          }")`,
          backgroundRepeat: "no-repeat",
        }}
        className="h-48 md:h-72 lg:h-[456px]"
      >
        <div className="text-white px-8 md:px-20 py-20 md:py-40">
          <div className="font-semibold uppercase text-2xl sm:text-5xl mx-1">
            {header}
          </div>
          <div className=" my-4 px-2 w-full text-justify">
            <p>{desc}</p>
          </div>
        </div>
      </div>
      <div className="mx-8 md:mx-20 px-2 my-2">
        <div className="flex flex-row space-x-1">
          <Link to="/">
            <p>Home</p>
          </Link>{" "}
          {to && (
            <Link to={to} className="flex flex-row space-x-1">
              {" "}
              <ChevronRight /> <p>{parent}</p>
            </Link>
          )}
          {to && (
            <span
              className=" flex flex-row space-x-1"
              style={{ color: "#008e51" }}
            >
              {" "}
              <ChevronRight /> <p>{to ? header : parent}</p>
            </span>
          )}
          {!parent && (
            <span
              className=" flex flex-row space-x-1"
              style={{ color: "#008e51" }}
            >
              {" "}
              <ChevronRight /> <p>{header}</p>
            </span>
          )}
        </div>
      </div>
    </>
  );
};
