import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getManagers = () => {
  return axios.get("/managers/governing-council");
};

export const useManagers = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["managers"],
    queryFn: () => getManagers(),
  });
};
