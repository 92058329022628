import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getManagementDetails = () => {
  return axios.get(`/pages/slug/management`);
};

export const useManagementDetails = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["managementDetails"],
    queryFn: () => getManagementDetails(),
  });
};
