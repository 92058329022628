/** @format */

import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import MaleAvatar from "assets/images/man-avatar.jpeg";

export const SecondaryDepartment = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Secondary Department"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <div className="m-2 text-justify">
              The Secondary department is responsible for, among others,
              designing and developing curricula and curriculum materials for
              secondary education.
              <br />
              <br />
              Secondary education is divided into two cycles: The Lower
              Secondary Education cycle referred to as Ordinary level that leads
              to the award of the Uganda Certificate of Education (UCE). This
              cycle lasts a period of four academic years. The Higher Secondary
              Education cycle referred to as Advanced Secondary level of
              education that leads to the award of the Uganda Advanced
              Certificate of Education (UACE).
              <br />
              <br />
              The main activities of the secondary department this financial
              year were to; re-organise the lower secondary curriculum via
              Subject Approach. After stakeholder consultations with the Vice
              Chancellors of both Public and Private Universities, Principals of
              NTCs and Deans of faculties of Education and Sciences, a reduced
              curriculum menu of 20 subjects was agreed. The curriculum was
              approved by His Excellency, the President at the April 5th 2018
              meeting with the Stakeholder in his office. Fine-tuning of the
              syllabuses is being finalised and arrangements for preliminary
              activities for roll out in 2020 are in high gear.
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={MaleAvatar}
              alt="Mrs. Enid Nyendwoha"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">John Okumu</p>
            <p className="">Manager Secondary Department</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
