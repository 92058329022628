/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import defaultLogo from "assets/images/white_bg_logo.jpg";
import { Link } from "react-router-dom";
import MediaBanner from "./MediaBanner";

export const Navbar = ({ page }) => {
  return (
    <>
      <header className="sticky top-0 z-50 bg-white">
        <MediaBanner />
        <nav
          className="relative w-full flex flex-wrap items-center justify-between lg:px-20 
			 text-md shadow-lg navbar navbar-expand-lg navbar-light"
          style={{ color: "#008e51" }}
        >
          <div className="container-fluid w-full flex items-center justify-between ">
            <button
              className="navbar-toggler border-0 hover:shadow-none 
						hover:no-underline py-2 px-2.5 bg-transparent focus:outline-none 
						focus:ring-0 focus:shadow-none focus:no-underline"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="bars"
                className="w-6"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                ></path>
              </svg>
            </button>
            <div
              className="collapse navbar-collapse flex-grow items-center"
              id="navbarSupportedContent"
            >
              <Link className="flex items-center p-2 w-32" to="/">
                <img
                  src={defaultLogo}
                  className="w-12/12"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
              <ul className="navbar-nav ml-10 flex flex-wrap flex-col pl-0 list-style-none mr-auto text-sm">
                <li className="nav-item mx-1">
                  <Link
                    className={
                      page === "home"
                        ? "nav-link font-semibold p-0 "
                        : "nav-link p-0"
                    }
                    to="/"
                  >
                    <p
                      className={
                        page === "home"
                          ? "cursor-pointer whitespace-nowrap text-md 2xl:text-lg bg-green-500 px-3 py-2 rounded-md text-white transition ease-in-out delay-50 duration-300"
                          : "cursor-pointer whitespace-nowrap text-md 2xl:text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300"
                      }
                    >
                      Home
                    </p>
                  </Link>
                </li>
                <li className="nav-item mx-1 p-0">
                  <div className="nav-link relative group">
                    <a
                      href="#"
                      className={
                        page === "about"
                          ? "nav-link font-semibold p-0 "
                          : "nav-link p-0"
                      }
                    >
                      <p
                        className={
                          page === "about"
                            ? "cursor-pointer whitespace-nowrap text-md 2xl:text-lg bg-green-500 px-3 py-2 rounded-md text-white transition ease-in-out delay-50 duration-300"
                            : "cursor-pointer whitespace-nowrap text-md 2xl:text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300"
                        }
                      >
                        About Us
                      </p>
                    </a>
                    <div
                      className="items-center absolute border border-t-0 rounded-b-lg
                   bg-white p-2 invisible group-hover:visible z-10"
                    >
                      <Link
                        to="/about"
                        className="px-0 py-2 block hover:font-semibold"
                      >
                        <p className="cursor-pointer whitespace-nowrap text-md hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300">
                          About NCDC
                        </p>
                      </Link>
                      <Link
                        to="/about/governance"
                        className="px-0 py-2 block hover:font-semibold"
                      >
                        <p className="cursor-pointer whitespace-nowrap text-md hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300">
                          Governance
                        </p>
                      </Link>
                      {/* <Link
                      to="/about/ncdc-functions"
                      className="px-4 py-2 block hover:font-semibold"
                    >
                      <p className="cursor-pointer whitespace-nowrap text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300">NCDC Functions</p>
                    </Link> */}
                    </div>
                  </div>
                </li>
                <li className="nav-item mx-1 p-0">
                  <Link
                    to="/management"
                    className={
                      page === "management"
                        ? "nav-link font-semibold p-0 "
                        : "nav-link p-0"
                    }
                  >
                    <p
                      className={
                        page === "management"
                          ? "cursor-pointer whitespace-nowrap text-md 2xl:text-lg bg-green-500 px-3 py-2 rounded-md text-white transition ease-in-out delay-50 duration-300"
                          : "cursor-pointer whitespace-nowrap text-md 2xl:text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300"
                      }
                    >
                      Management
                    </p>
                  </Link>
                </li>
                <li className="nav-item mx-1 p-0">
                  <Link
                    className={
                      page === "service"
                        ? "nav-link font-semibold p-0 "
                        : "nav-link p-0"
                    }
                    to="/services"
                  >
                    <p
                      className={
                        page === "service"
                          ? "cursor-pointer whitespace-nowrap text-md 2xl:text-lg bg-green-500 px-3 py-2 rounded-md text-white transition ease-in-out delay-50 duration-300"
                          : "cursor-pointer whitespace-nowrap text-md 2xl:text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300"
                      }
                    >
                      Services
                    </p>
                  </Link>
                </li>
                <li className="nav-item mx-1 p-0">
                  <Link
                    className={
                      page === "e-learning"
                        ? "nav-link hover: font-semibold p-0"
                        : "nav-link p-0"
                    }
                    to="/e-learning"
                  >
                    <p
                      className={
                        page === "e-learning"
                          ? "cursor-pointer whitespace-nowrap text-md 2xl:text-lg bg-green-500 px-3 py-2 rounded-md text-white transition ease-in-out delay-50 duration-300"
                          : "cursor-pointer whitespace-nowrap text-md 2xl:text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300"
                      }
                    >
                      E-Learning
                    </p>
                  </Link>
                </li>
                <li className="nav-item mx-1 p-0">
                  <Link
                    className={
                      page === "resources"
                        ? "nav-link font-semibold p-0 "
                        : "nav-link p-0"
                    }
                    to="/resources"
                  >
                    <p
                      className={
                        page === "resources"
                          ? "cursor-pointer whitespace-nowrap text-md 2xl:text-lg bg-green-500 px-3 py-2 rounded-md text-white transition ease-in-out delay-50 duration-300"
                          : "cursor-pointer whitespace-nowrap text-md 2xl:text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300"
                      }
                    >
                      Resources
                    </p>
                  </Link>
                </li>
                <li className="nav-item mx-1 p-0">
                  <div className="nav-link relative group">
                    <a
                      href="#"
                      className={
                        page === "opportunity"
                          ? "nav-link font-semibold p-0 "
                          : "nav-link p-0"
                      }
                    >
                      <p
                        className={
                          page === "opportunity"
                            ? "cursor-pointer whitespace-nowrap text-md 2xl:text-lg bg-green-500 px-3 py-2 rounded-md text-white transition ease-in-out delay-50 duration-300"
                            : "cursor-pointer whitespace-nowrap text-md 2xl:text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300"
                        }
                      >
                        Opportunities
                      </p>
                    </a>
                    <div
                      className="items-center absolute border border-t-0 rounded-b-lg
                   bg-white p-2 invisible group-hover:visible z-10"
                    >
                      <Link
                        to="/careers"
                        className="px-0 py-2 block hover:font-semibold"
                      >
                        <p className="cursor-pointer whitespace-nowrap text-md hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300">
                          Career
                        </p>
                      </Link>
                      <Link
                        to="/tenders-and-bids"
                        className="px-0 py-2 block hover:font-semibold"
                      >
                        <p className="cursor-pointer whitespace-nowrap text-md hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300">
                          Tenders & Bids
                        </p>
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="nav-item mx-1 p-0">
                  <Link
                    className={
                      page === "faq"
                        ? "nav-link font-semibold p-0 "
                        : "nav-link p-0"
                    }
                    to="/faqs"
                  >
                    <p
                      className={
                        page === "faq"
                          ? "cursor-pointer whitespace-nowrap text-md 2xl:text-lg bg-green-500 px-3 py-2 rounded-md text-white transition ease-in-out delay-50 duration-300"
                          : "cursor-pointer whitespace-nowrap text-md 2xl:text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300"
                      }
                    >
                      FAQs
                    </p>
                  </Link>
                </li>
                <li className="nav-item mx-1 p-0">
                  <Link
                    className={
                      page === "contact"
                        ? "nav-link font-semibold p-0 "
                        : "nav-link p-0"
                    }
                    to="/contact"
                  >
                    <p
                      className={
                        page === "contact"
                          ? "cursor-pointer whitespace-nowrap text-md 2xl:text-lg bg-green-500 px-3 py-2 rounded-md text-white transition ease-in-out delay-50 duration-300"
                          : "cursor-pointer whitespace-nowrap text-md 2xl:text-lg hover:bg-green-500 px-3 py-2 rounded-md hover:text-white transition ease-in-out delay-50 duration-300"
                      }
                    >
                      Contact Us
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
