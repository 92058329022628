import { Gallery } from "./Gallery";
import { Multimedia } from "./Multimedia";

export const MediaSection = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
      <div>
        <div className="md:mt-2 flex justify-between">
          <h1 className="font-extrabold text-xl">Multimedia</h1>
        </div>
        <Multimedia />
      </div>
      <div>
        <div className="md:mt-2 flex justify-between">
          <h1 className="font-extrabold text-xl">Gallery</h1>
        </div>
        <Gallery />
      </div>
    </div>
  );
};
