import { Form, SearchField } from "components/Form";
import * as z from "zod";
import { useSearchFor } from "../api/search";
import { useNavigate } from "react-router-dom";
import { Search } from "react-feather";
import { SpinnerCircular } from "spinners-react";

const schema = z.object({
  q: z.string().min(1, "Required"),
});

export const SearchFor = () => {
  const SearchForMutation = useSearchFor();

  const navigate = useNavigate();

  return (
    <>
      <Form
        id="search-for"
        onSubmit={async (values) => {
          await SearchForMutation.mutateAsync(values.q);
          localStorage.setItem("q", values.q);
          navigate(`/search/q/${values.q}`);
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <div className="flex justify-center">
              <div className="bg-white my-2 input-group flex text-gray-700 w-full px-3 py-1.5 text-sm font-normal">
                <SearchField
                  error={formState.errors["q"]}
                  registration={register("q")}
                />
                {SearchForMutation.isLoading ? (
                  <SpinnerCircular size={14} />
                ) : (
                  <button type="submit">
                    <Search className="p-1" />
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </Form>
    </>
  );
};
