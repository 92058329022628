import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import SansaAvatar from "assets/profiles/Sansa Ambrose 2.jpg";

export const Planning = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader header="Planning" to="/management" parent="Management" />

      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <p className="m-2 md:pt-10">
              Monitors and provides technical expertise in the accounting,
              treasury management, and financial reporting Coordinates the
              design and development of the Finance Policy Manual, policies and
              procedures in finance Prepares annual department work plans,
              budgets, investment plans for submission to the supervisor. Guides
              the Budgetary Committee during the budgeting process Ensures
              effective management of the pay roll
            </p>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={SansaAvatar}
              alt="Sansa Ambrose"
              className="h-60 w-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2 text-center">Sansa Ambrose</p>
            <p className=" text-center">Planner</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
