/* eslint-disable no-unused-vars */
import React from "react";
import backgroundImage from "assets/images/building.jpg";
import { Link } from "react-router-dom";
import { Linkedin, Facebook, Twitter, Youtube, Lock } from "react-feather";
import { Newsletter } from "features/newsletter/Newsletter";
import court from "assets/images/flag.png";
import NITALOGO from "assets/nita.png";

export const Footer = () => {
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );

  return (
    <div className="mt-12 relative">
      <div className="mx-10 relative">
        <div className="grid grid-cols-12">
          <div className="col-span-3 h-1 bg-gradient-to-l from-black"></div>
          <div className="col-span-6 h-1 bg-black"></div>
          <div className="col-span-3 h-1 bg-gradient-to-r from-black "></div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 h-1 bg-gradient-to-l from-yellow-500"></div>
          <div className="col-span-8 h-1 bg-yellow-500"></div>
          <div className="col-span-2 h-1 bg-gradient-to-r from-yellow-500 "></div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 h-1 bg-gradient-to-l from-red-500"></div>
          <div className="col-span-8 h-1 bg-red-500"></div>
          <div className="col-span-2 h-1 bg-gradient-to-r from-red-500"></div>
        </div>
      </div>
      <div
        style={{
          backgroundAttachment: "fixed",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{ backgroundColor: "#000000f1" }}
          className="pt-2 px-8 lg:px-20 text-white"
        >
          <div className="-mt-12 md:-mt-16 absolute flex left-1/2 transform -translate-x-1/2">
            <img src={court} alt="..." className="w-16 md:w-24" />
          </div>
          <div className="mt-10">
            <div className="grid grid-row p-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
              <div className="col-span-1">
                <h5 className="mb-2  text-lg text-green-700">
                  {/* <p className="text-md">Site Links</p> */}
                  Site Links
                </h5>
                <ul className="flex flex-col text-sm text-gray-300">
                  <Link to="/about" className="hover:text-green-700 mb-2">
                    <p className="text-md">About NCDC</p>
                  </Link>
                  <Link
                    to="/tenders-and-bids"
                    className="hover:text-green-700 mb-2"
                  >
                    <p className="text-md">Tenders & Bidders</p>
                  </Link>
                  <Link to="/faqs" className="hover:text-green-700 mb-2">
                    <p className="text-md">FAQs</p>
                  </Link>
                  <Link to="/careers" className="hover:text-green-700 mb-2">
                    <p className="text-md">Jobs & Careers</p>
                  </Link>
                  <a
                    href="https://ncdc.umcs.go.ug/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-green-700 mb-2"
                  >
                    Web Mail
                  </a>
                </ul>
              </div>
              <div className="col-span-2 mx:ml-8">
                <h5 className="mb-2  text-lg text-green-700">
                  {/* <p className="text-md">Important Links</p> */}
                  Important Links
                </h5>
                <ul className="flex flex-col text-sm text-gray-300">
                  <a
                    className="hover:text-green-700 mb-2"
                    href="https://www.education.go.ug/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="text-md">Ministry of Education and Sports</p>
                  </a>
                  <a
                    className="hover:text-green-700 mb-2"
                    href="https://uneb.ac.ug/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="text-md">
                      Uganda National Examinations Board (UNEB)
                    </p>
                  </a>
                  <a
                    className="hover:text-green-700 mb-2"
                    href="http://www.ubteb.go.ug/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="text-md">
                      Uganda Business and Technical Examination Board (UBTEB)
                    </p>
                  </a>
                  <a
                    className="hover:text-green-700 mb-2"
                    href="https://unche.or.ug/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="text-md">
                      National Council For Higher Education (NCHE)
                    </p>
                  </a>
                </ul>
              </div>
              <div className="col-span-2 mx:ml-10">
                <h5 className="mb-2 text-lg text-green-700">
                  {/* <p className="text-md">Contact Us</p> */}
                  Contact Us
                </h5>
                <ul className="flex flex-col text-sm text-gray-300">
                  <li className="mb-2">
                    <p className="text-md">Plot M838, Kyambogo.</p>
                  </li>
                  <li className="mb-2">
                    <p className="text-md">P.O.Box 7002 Kampala, Uganda</p>
                  </li>
                  <li className="mb-2">
                    <p className="text-md">+256-393-112088</p>
                  </li>
                  <li className="mb-2">
                    <p className="text-md">Follow us on</p>
                  </li>
                  <span className="flex space-x-3">
                    <a
                      href="https://facebook.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-green-700 mb-2 grid h-8 w-8 place-content-center rounded-full bg-slate-500"
                    >
                      <Facebook />
                    </a>
                    <a
                      href="https://twitter.com/NCDCUg"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-green-700 mb-2 grid h-8 w-8 place-content-center rounded-full bg-slate-500"
                    >
                      <Twitter />
                    </a>
                    <a
                      href="https://www.youtube.com/c/NCDCUgEduc"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-green-700 mb-2 grid h-8 w-8 place-content-center rounded-full bg-slate-500"
                    >
                      <Youtube />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/national-curriculum-development-centre/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-green-700 mb-2 grid h-8 w-8 place-content-center rounded-full bg-slate-500"
                    >
                      <Linkedin />
                    </a>
                  </span>
                </ul>
              </div>
              <div className="col-span-1">
                <h5 className="mb-2  text-lg text-green-700">
                  {/* <p className="text-md">Newsletter</p> */}
                  Our NewsLetter
                </h5>
                <ul className="flex flex-col text-sm text-gray-300">
                  <li className="mb-2">
                    <p className="text-md">Sign up today!</p>
                  </li>
                  <Newsletter />
                </ul>
              </div>
            </div>
            <div className="bg-green-700 h-0.5"></div>
            <div className="flex flex-col md:grid md:grid-cols-12 justify-center text-sm py-2 px-2">
              <div className="col-span-10 text-center">
                <p className="text-md">
                  © Copyright {currentYear} - National Curriculum Development
                  Centre (NCDC) | All rights reserved.
                </p>
              </div>
              <div className="col-span-2 flex ml-2">
                <p className="mt-4 mb-4 md:mb-0 md:mt-0 text-center text-xs text-white flex flex-row items-center space-x-1 dark:text-gray-400">
                  <span className="flex flex-row items-center">
                    <Lock className="h-3 text-white" />
                    <span className="text-md">Powered by </span>{" "}
                  </span>
                  <a href="https://nita.go.ug" target="_blank" rel="noreferrer">
                    <img src={NITALOGO} alt="NITA-U" className="h-6" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
