import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { MainLayout, PageHeader } from "components/layout";
import { UPLOADS_URL } from "config";
import { useServicesDetails } from "../api/getPageDetails";
import { useServices } from "../api/getServices";
import { Spinner } from "components/elements";
import { Service } from "./Service";

export const Services = (props) => {
  const { slug } = useParams();

  const servicesQuery = useServices();
  const detailsQuery = useServicesDetails();

  var activeBtn = slug;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (servicesQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!servicesQuery.data) return null;

  return (
    <MainLayout page="service">
      <PageHeader
        header={detailsQuery.data?.title || "Undefined"}
        desc={detailsQuery.data?.desc}
        backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
      />
      <div className="w-full px-20 my-2">
        <div className="">
          {/* Drop down */}
          <div className="flex lg:hidden justify-center py-4">
            <div>
              <div className="dropdown relative">
                <a
                  className="dropdown-toggle p-4 text-black font-semibold text-lg leading-tight rounded 
                  shadow-md transition duration-150 ease-in-out flex items-center"
                  href="/"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {servicesQuery.data[activeBtn]?.title}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    className="w-2 ml-2"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                </a>
                <ul
                  className="dropdown-menu min-w-full absolute bg-white text-base z-50 float-left
                   py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding 
                   border-none hidden"
                  aria-labelledby="dropdownMenuButton2"
                >
                  {servicesQuery.data?.map((name, index) => (
                    <li
                      key={index}
                      className={
                        activeBtn === index
                          ? "dropdown-item block bg-transparent p-4 font-semibold border-b-2 border-green-400 text-green-500"
                          : "dropdown-item block bg-transparent p-4 font-semibold hover:text-green-500"
                      }
                    >
                      <Link to={`/services/${name.slug}`}>{name.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Main menu */}
          <div className="hidden lg:flex text-center w-full">
            <ul className="flex space-x-2">
              {servicesQuery.data?.map((name, index) => (
                <Link
                  to={`/services/${name.slug}`}
                  className="cursor-pointer"
                  key={index}
                >
                  <li
                    key={index}
                    className={
                      activeBtn === index
                        ? " cursor-pointer py-2 bg-green-500 rounded px-2 text-white"
                        : "cursor-pointer py-2 px-2 rounded hover:bg-green-500 hover:text-white hover:h-fit"
                    }
                  >
                    <p className="cursor-pointer">{name.title}</p>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
        <div className="">
          {servicesQuery.data[activeBtn]?.title ===
            servicesQuery.data.title && (
            <Service slug={servicesQuery.data.slug} />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

Services.propTypes = {
  category: PropTypes.string,
};
