import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { MainLayout, PageHeader } from "components/layout";
import { usePost } from "../api/getPost";
import { Spinner, MDPreview } from "components/elements";
// import formatDate from "utils/formatDate";
import { usePosts } from "../api/getPosts";
import { formatHTMLToFirstSentence } from "utils/formatHTMLToFirstSentence";
import { UPLOADS_URL } from "config";

export const Post = () => {
  const { slug } = useParams();

  const postQuery = usePost({ slug });

  const postsQuery = usePosts();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (postQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        {/* add skeleton loading */}
        <Spinner size="xl" />
      </div>
    );
  }

  if (postsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  console.log(postQuery.data);

  if (!postQuery.data) return null;
  if (!postsQuery.data) return null;

  return (
    <MainLayout>
      <PageHeader header={postQuery.data.title} to="/news" parent="News" />

      <div className="lg:mx-20 my-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <div className="px-2">
              <img
                style={{
                  width: "50vw",
                }}
                className="rounded-md"
                src={`${UPLOADS_URL}/${postQuery.data.image}`}
                alt={postQuery.data.title}
              />
              <div className="my-2 mt-4">
                <span className="text-2xl">{postQuery.data.title}</span>
              </div>
              <div className="my-2">
                <span className="text-xs">
                  {/* {formatDate(postQuery.data.createdAt)} */}
                </span>
              </div>
            </div>
            <MDPreview value={postQuery.data.desc} />
          </div>
          <div className="mx-auto">
            <div>
              <div className="text-2xl mb-2">Latest News</div>
              <div className="grid grid-cols-1 gap-4">
                {postsQuery.data.map((post, index) => {
                  return (
                    <Link
                      key={index}
                      to={`/news/${post.slug}`}
                      className="my-2"
                    >
                      <div className="card bg-white w-80 overflow-hidden p-6 space-y-4 border hover:shadow-xl cursor-pointer">
                        <div className="space-y-1">
                          <h2 className=" font-semibold text-md transition hover:text-cyan-300">
                            {formatHTMLToFirstSentence(post.title)}
                          </h2>
                          <p className=" text-sm select-none text-justify">
                            {formatHTMLToFirstSentence(post.desc)
                              .split(" ")
                              .slice(0, 10)
                              .join(" ")}{" "}
                            ...
                          </p>
                          <p className="text-sm font-bold cursor-pointer">
                            {/* Posted: {formatDate(post.createdAt)} */}
                          </p>
                        </div>
                        <div className="h-20 overflow-hidden">
                          <img
                            className="w-full rounded-md transition hover:bg-cyan-300"
                            src={`${UPLOADS_URL}/${post.image}`}
                            alt={post.title}
                          />
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
