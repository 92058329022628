import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageHeader, ShopLayout } from "components/layout";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "react-feather";
import { MDPreview } from "components/elements";
import { UPLOADS_URL } from "config";

export function Cart() {
  const navigate = useNavigate();
  const cartQuery = JSON.parse(localStorage.getItem("cart"));

  const [total, setTotal] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // total price
    let totalPrice = 0;
    cartQuery.map((item) => {
      var quantity = 1;
      totalPrice += item.price * quantity;
      return totalPrice;
    });
    localStorage.setItem("cartTotal", JSON.stringify(totalPrice));
    setTotal(totalPrice);
  }, [cartQuery]);

  const calculateTotal = () => {
    let total = 0;
    cartQuery.forEach((item) => {
      total += item.price;
    });
    return total;
  };

  return (
    <ShopLayout>
      <PageHeader header="Cart" desc="Your cart items" />
      <div className="mx-20 mt-10">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 items-center">
          <div className="col-span-12">
            {cartQuery && cartQuery.length ? (
              <div className="card my-4 border-b border-gray-300 pb-4">
                <div className="hidden md:grid grid-cols-5 font-semibold">
                  <div className="col-span-3">Product</div>
                  <div className="col-span-1">Action</div>
                  <div className="col-span-1">Total Price</div>
                </div>
              </div>
            ) : null}
            {cartQuery && cartQuery.length ? (
              cartQuery.map((item, index) => (
                <div className="card my-2 border border-gray-300 py-4">
                  <div className="grid grid-cols-1 md:grid-cols-5 items-center">
                    <div className="col-span-1 mx-auto">
                      <img
                        src={`${UPLOADS_URL}/resources/${item.cover}`}
                        alt={item.title}
                        className="w-32 h-32 rounded-lg"
                      />
                    </div>
                    <div className="col-span-2 text-center">
                      <p className="font-semibold text-lg inline-block">
                        {item.title}
                      </p>
                      <MDPreview value={item.desc} />
                    </div>
                    <div className="col-span-1 text-center mx-auto md:flex-row">
                      {/* <div className="border border-gray-300 inline-block px-2">
                        <button
                          onClick={() => decrementQuantity(item._id)}
                          className="font-bold border border-black rounded-full px-2"
                        >
                          +
                        </button>
                        <p className="inline-block py-2 px-4 lg:px-6">
                          {item.quantity || itemQuantity}
                        </p>
                        <button
                          onClick={() => incrementQuantity(item._id)}
                          className="font-bold border border-black rounded-full px-2"
                        >
                           -
                        </button>
                      </div> */}
                      <button
                        className="block mx-auto my-2 p-2 hover:bg-green-800 bg-green-600 px-6 text-white rounded-full"
                        onClick={() => {
                          const newCart = cartQuery.filter(
                            (x) => x.id !== item.id
                          );
                          localStorage.setItem("cart", JSON.stringify(newCart));
                          navigate("/resources");
                        }}
                      >
                        <p className="inline-block"> Remove</p>
                      </button>
                    </div>
                    <div className="col-span-1">
                      <p className="font-semibold">Ugshs {total}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center h-60 mt-20">
                <p className="text-lg mb-2">Your cart is empty</p>
                <Link
                  to="/resources"
                  className="text-lg bg-green-600 p-2 rounded text-white"
                >
                  Go to shop
                </Link>
              </div>
            )}
          </div>
          {cartQuery && cartQuery.length ? (
            <div className="col-span-12 border border-gray-300 p-8 mb-10">
              <div className="flex flex-col md:flex-row mx-20 justify-between font-semibold text-lg">
                <p className="text-2xl">Total</p>
                <p className="my-2">Ugsh {calculateTotal()}</p>
              </div>
              <div className="flex flex-row justify-end">
                <Link
                  to="/cart/checkout"
                  className="bg-green-600 flex justify-between p-4 w-1/2 my-4 lg:my-8 rounded-full font-semibold hover:bg-green-800 text-white"
                >
                  <span>Checkout</span>
                  <ArrowRight />
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </ShopLayout>
  );
}
