import React from "react";
import { Link } from "react-router-dom";
import { MainLayout, PageHeader } from "components/layout";
import { ChevronsRight, ChevronsDown } from "react-feather";
import { useState } from "react";
import { useManagementDetails } from "../api/getPageDetails";
import { UPLOADS_URL } from "config";
import { DirectoratesList } from "../components/DirectoratesList";

export const Management = () => {
  const detailsQuery = useManagementDetails();

  return (
    <MainLayout page="management">
      <PageHeader
        header={detailsQuery.data?.title || "Undefined"}
        desc={detailsQuery.data?.desc}
        backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
      />
      <div className="mx-8 lg:mx-20 my-12 px-2">
        <DirectoratesList />
      </div>
    </MainLayout>
  );
};
