import { Circle } from "react-feather";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { useNotices } from "../api/getNotices";

export const NoticesList = () => {
  const noticesQuery = useNotices();

  // console.log(noticesQuery);

  if (noticesQuery.isLoading) {
    return (
      <div>
        <Skeleton height={180} width={250} />
      </div>
    );
  }

  if (!noticesQuery.data) return null;

  return (
    <div
      className="flex flex-col items-start border border-red-200 text-md
      w-full shadow-lg p-4 mt-2 md:py-2 h-48 text-black"
    >
      <div className="grid grid-col">
        {noticesQuery.data?.map((notice, index) => (
          <div key={index} className="">
            <Link
              to={`./notices/${notice.slug}`}
              className="flex flex-row space-x-1 items-baseline hover:underline my-4"
            >
              <Circle className="h-3 font-bold" /> {notice.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
