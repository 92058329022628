/** @format */
import { TwitterTimelineEmbed } from "react-twitter-embed";
import React from "react";
import { MainLayout } from "components/layout";
import { Banner } from "../components/Banner";
import { KeyMileStones } from "../components/KeyMileStones";
import { NewsEvents } from "../components/NewsEvents";
import { Section } from "../components/Section";
import { MediaSection } from "../components/MediaSection";

export const Home = () => {
  return (
    <MainLayout page="home">
      <Banner />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8 lg:gap-16 my-2 mx-8 lg:mx-20">
        <div className="">
          <KeyMileStones />
        </div>
        <div className="mx-2">
          <div className="grid grid-cols-1 gap-2">
            <div>
              <Section />
            </div>
            <div>
              <MediaSection />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-8 ">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 items-start lg:items-end">
          <div className="col-span-1 lg:col-span-2">
            <NewsEvents />
          </div>
          <div className="col-span-1 mt-12">
            <div className="mb-3 md:w-2/3 md:mx-auto lg:w-full h-96 overflow-scroll border border-gray-200 shadow-xl hover:shadow-2xl cursor-pointer">
              <Twitter />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

function Twitter() {
  return (
    <TwitterTimelineEmbed
      sourceType="timeline"
      screenName="NCDCUg"
      options={{ height: 400 }}
    />
  );
}
