import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import MugangaAvatar from "../../../assets/profiles/Christopher Kagolo Muganga 2.jpg";
import { ResearchTeam } from "./ResearchTeam";

export const ResearchConsultancy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout page="management">
      <PageHeader
        header="Research, Evaluation and Consultancy"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify px-2">
            <p className="my-4">
              NCDC has a vibrant research and evaluation department whose
              strategic objective is to provide relevant and up-to-date
              information for curriculum Development. The department has
              continued to inform and guide the curriculum development process
              in this country. The Research and Evaluation department at NCDC is
              a cross cutting department that supports other academic
              departments in research and evaluation related activities. It
              consists of two units. The Research and evaluation and the library
              unit.
            </p>
            <p className="my-4">
              The responsibilities of the Research and Evaluation department
              include; conducting research and evaluation studies in curricula
              and educational programs; Evaluate the effectiveness of
              instructional materials and methods in educational institutions;
              training panel members in research methods and techniques;
              producing research reports for curriculum planning and management;
              disseminating information and findings to promote understanding
              and better practices of curriculum implementation; maintaining an
              efficient document unit and providing relevant and up-to-date
              information resources for curriculum development.
            </p>
            <p className="my-4">
              The department of recent has engaged in a number of activities
              including; dissemination of the findings on the Effectiveness of
              the Uganda Primary Four Curriculum in the four regions in Uganda;
              A study on assessing the implementation of the Subsidiary
              Mathematics and Subsidiary Information Technology Curricula at
              Advanced level.
            </p>
            <p className="font-semibold">The Library Unit</p>
            <p className="my-2">
              The Library is a Unit under the Research and Evaluation Department
              with an aim of providing relevant and up-to-date information for
              Curriculum development. It consists of the main library (reading
              space), the front/service desk, and the Librarian’s office which
              has the Reserve, Special collection, and the Reference sections.
            </p>
            <p className="my-2">
              It has a collection of about 7,700 books including syllabi for
              Pre-Primary and Primary, Secondary and BTVET levels; textbooks for
              different subjects (Curriculum development, Research, Human
              resource management, Economics, Physics, Biology, etc); UNEB Past
              papers; various Government publications; Newspaper dailies; local
              language Orthographies; and other reference materials. The Library
              subscribes to and is a member to the Consortium of Uganda
              University Libraries (CUUL) and this enables it have access to at
              least 32 Electronic Databases Other Library Services include:
              providing lending services to staff, Current Awareness Services,
              Reference Services, User education and Training, Issuance of the
              ISBN & ISSN to the Production unit, Selective Dissemination of
              Information, and assisting in the acquisition of Copyright for
              NCDC publications.
            </p>
            Functions
            <ul className="list-disc">
              <li>
                Controls, guides and implements the Centre’s research and
                consultancy programs
              </li>
              <li>
                Coordinates the initiation, planning and development of a
                research and consultancy strategy for the organization that
                contributes to the overall development of the Centre’s strategic
                plan
              </li>
              <li>
                Coordinates research and consultancy projects and monitor
                contract performance to ensure that they deliver timely and
                cost-effective results that enhance the effectiveness of the
                Centre’s service delivery.
              </li>
            </ul>
          </div>
          <div className="mx-auto col-span-2">
            <img
              src={MugangaAvatar}
              alt="Christopher Muganga"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2 text-center">
              Muganga Kagolo Christopher
            </p>
            <p className="text-center">
              Acting Deputy Director, Research Consultancy and Library services
            </p>
          </div>
        </div>
        <ResearchTeam />
      </div>
    </MainLayout>
  );
};
