import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import DefaultAvatar from "assets/images/man-avatar.jpeg";

export const ScienceTechEquipment = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Science and Technology, Equipment Production"
        to="/management"
        parent="Management"
      />

      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <p className="my-2">
              The Science and Technology Equipment Production Unit (STEPU) was
              set in 1987 under the National Curriculum Development Centre
              (NCDC) Kyambogo with financing from the 4th IDA project to
              establish production of low cost equipment using locally available
              materials.
              <br />
              <br />
              The Unit recently concluded the following activities: production
              of Class, office, library furniture for Solberg S.S in Kabale;
              class furniture for Greenhill Academy; St Francis nursery and
              primary school; Blackboard equipment for order and for stock.
              <br />
              <br />
              The running activities and upcoming activities include; 60
              2-seater desks and 120 chairs; Geometrical equipment on order; 15
              3-seater desks and 2 pews and 320 pieces of slates.
            </p>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={DefaultAvatar}
              alt="avatar"
              className="h-60 w-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2 text-center">
              <>Name</>
            </p>
            <p className="text-center">Title</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
