import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import DefaultAvatar from "assets/images/man-avatar.jpeg";

export const HumanResource = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Human Resource and Administration"
        to="/management"
        parent="Management"
      />

      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <p className="font-semibold my-2">Functions</p>
            <ul className="list-disc my-2">
              <li>
                Plans, directs, oversees and coordinates the Human Resource and
                Administrative function at the Centre.
              </li>
              <li>
                Coordinates the designing and implementation of effective
                regular reviews of Human Resource policies, procedures and
                programmes for harmonious working relationships.
              </li>
              <li>
                Develops and coordinates employee induction and on the job-
                orientation courses aimed at providing employees with relevant
                information about their work and the Centre.
              </li>
              <li>
                Conducts training needs assessments and prepares staff Training
                and Development programmes under the guidance of the Human
                Resource Manager.
              </li>{" "}
            </ul>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={DefaultAvatar}
              alt="Habiyaremye John"
              className="h-60 w-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2 text-center">
              <>Habiyaremye John</>
            </p>
            <p className="text-center">
              Deputy Director, Finance HR & Administration
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
