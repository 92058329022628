import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import FemaleAvatar from "assets/images/female-avatar.jpeg";
import MaleAvatar from "assets/images/man-avatar.jpeg";

export const SpecialNeeds = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Special Needs Education (SNE)"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12 text-justify">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3">
            <div className="m-2 text-justify">
              <p className="font-semibold my-2">About us</p>
              <p className="">
                Special needs department aims at providing cross-cutting or
                Inclusive education issues done at the centre. Special needs
                education aims to provide accommodated education for learners
                with disabilities and special educational needs.
              </p>
              <div>
                <p className="my-2 font-semibold">Vision</p>
                <p className="">
                  Learners and teachers with special educational needs accessing
                  quality educational learning/instructional materials.
                </p>
              </div>
              <div>
                <p className="my-2 font-semibold">Mission</p>
                <p className="">
                  To coordinate and support the provision of quality and
                  relevant educational materials to all Ugandans.
                </p>
              </div>
              <p className="font-semibold my-2">
                General Activities of the Department.
              </p>
              <ul className="list-disc">
                <li>
                  Overseeing and coordinates all activities concerning special
                  needs and inclusiveness at the centre.
                </li>
                <li>
                  Oversees the development and implementation of special needs
                  and inclusive policies, procedures and plans for proper
                  management of the centres special needs and inclusive
                  resources.
                </li>
                <li>
                  To advocate for inclusive education (IE) through embracing
                  modification/adaptations in curricular, teaching methods,
                  teaching/learning resources and learning environments.
                </li>
                <li>
                  Advocates for change of behavior, attitudes, teaching methods,
                  curriculum, environments to address issues of quality and
                  equity{" "}
                </li>
                <li>
                  Advocates for allocation of human resource, material and
                  financial resources to meet the educational needs of all
                  learners.
                </li>
                <li>
                  Envisages the application of technology in teaching and
                  learning of learners with special educational needs to address
                  of issues of quality and equity.
                </li>
              </ul>
              <div className="my-2">
                <p className="font-semibold">Generic services provided</p>
                <ul className="list-disc">
                  <li>
                    Adaptation/modification of the existing curriculum materials
                    to suit the needs of different learners with special
                    education.
                  </li>
                  <li>
                    Curriculum material development that supports learners with
                    special educational needs.
                  </li>
                  <li>
                    Orientation of teachers and other key stakeholders on the
                    developed materials.
                  </li>
                  <li>
                    Training of teachers on the development of teaching and
                    learning materials using the locally available resources.
                  </li>
                  <li>Carrying out advocacy.</li>
                  <li>
                    Carrying out sensitization on issues of special education.
                  </li>
                  <li>
                    Counselling and guidance to teachers, learners and other key
                    stakeholders.
                  </li>
                  <li>
                    Carrying out home-based programs in homes with learners with
                    different barriers to learning and development.
                  </li>
                  <li>
                    Carrying out assessment in different areas of special needs.
                  </li>
                  <li>
                    Making referrals to relevant institutions that can provide
                    necessary assistance.
                  </li>
                  <li>
                    Assisting the concerned individuals in proper placement of
                    their children.
                  </li>
                </ul>
              </div>
              <div className="my-2">
                <p className="font-semibold">Additional information</p>
                <p>Examples of learners with special educational needs are</p>
                <ul className="list-disc">
                  <li>
                    Those with learning disabilities such as; dyslexia – having
                    difficulties in reading and writing.
                  </li>
                  <li>Those with communication disorders.</li>
                  <li>
                    Emotional and behavioral disorders (such as; attention
                    deficit hyperactive disorder ADHD).
                  </li>
                  <li>
                    Physical disabilities (such as; cerebral palsy, muscular
                    dystrophy, spinal bifida).
                  </li>
                  <li>
                    Development disabilities (such as; autistic spectrum
                    disorder and intellectual disabilities).
                  </li>
                  <li>
                    Sensory disabilities (such as; visual impairment and hearing
                    impairment).
                  </li>
                </ul>
              </div>
            </div>
            <div></div>
          </div>
          <div className="col-span-2 mx-auto text-center">
            <img
              src={FemaleAvatar}
              alt="Ms Kemizano Rosert"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Rosert Kemizano</p>
            <p className="">Manager Special Needs Education</p>
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-center space-x-10">
            <div>
              <img
                src={FemaleAvatar}
                alt="Ms Kazinda Generous"
                className="h-36 rounded-full border-4 border-green-400 mx-auto"
              />
              <p className="text-base mt-2">Ms Kazinda Generous</p>
              {/* <p className="">Manager, Human Resources and Administration</p> */}
            </div>
            <div>
              <img
                src={MaleAvatar}
                alt="Twinomugisha Alex"
                className="h-36 rounded-full border-4 border-green-400 mx-auto"
              />
              <p className="text-base mt-2">Twinomugisha Alex</p>
              {/* <p className="">Manager, Human Resources and Administration</p> */}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
