import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getManagement = () => {
  return axios.get("/managers/management");
};

export const useManagement = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["management"],
    queryFn: () => getManagement(),
  });
};
