import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getFaqDetails = () => {
  return axios.get(`/pages/slug/faqs`);
};

export const useFaqDetails = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["faqsDetails"],
    queryFn: () => getFaqDetails(),
  });
};
