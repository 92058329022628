import NationalKnoImage from "assets/images/national-knowledge.png";
import Report2020 from "../../../assets/pdf/Annual Report 2020-2021 BOOK.pdf";
import Report2021 from "../../../assets/pdf/Final Annual Report 2019-2020.pdf";
import Plan from "../../../assets/pdf/NCDC STRATEGIC PLAN2020_2021 TO 2024_2025 BOOK.pdf";
import Profile from "../../../assets/pdf/Profile of Planing Unit.docx";

export function Areas() {
  return (
    <>
      <div className="py-8 px-12 flex flex-col justify-center bg-slate-100">
        <p className="font-semibold my-2">
          <p className="text-2xl">Our strategic areas</p>
        </p>
        <p className="my-2 font-semibold">
          <p className="text-md">
            Our strategic areas of focus for the next five years shall be guided
            by the following key priority areas:
          </p>
        </p>
        <ul className="list-disc grid grid-cols-1 md:grid-cols-2 gap-2 text-gray-600">
          <li>
            <p className="text-md">
              Aligning A’ Level Curriculum with Revised O’ Level Curriculum
            </p>
          </li>
          <li>
            <p className="text-md">Research, Consultancy and Innovation</p>
          </li>
          <li>
            <p className="text-md">Revival of the literature Bureau</p>
          </li>
          <li>
            <p className="text-md">
              Improving profitability of STEP (Science, Technology and Equipment
              Production) Department
            </p>
          </li>
          <li>
            <p className="text-md">
              Reviewing the Learning Framework for Early Childhood Care and
              Education
            </p>
          </li>
          <li>
            <p className="text-md">Reviewing the Primary Curriculum</p>
          </li>
          <li>
            <p className="text-md">Stakeholder and Community engagement</p>
          </li>
          <li>
            <p className="text-md">
              Supporting the formation of Language Boards with focus on minority
              ethnic communities
            </p>
          </li>
          <li>
            <p className="text-md">
              Organizational and institutional development
            </p>
          </li>
          <li>
            <p className="text-md">
              Integration of ICT and Remote Learning in teaching and learning
              methodologies in curriculum
            </p>
          </li>
          <li>
            <p className="text-md">
              Instructional materials development, publishing and information
              sharing
            </p>
          </li>
          <li>
            <p className="text-md">
              Regulation of Alternative Curricula and quality of instructional
              materials
            </p>
          </li>
          <li>
            <p className="text-md">
              Strengthening curriculum interpretation and implementation support
            </p>
          </li>
        </ul>
        <div className="my-4 space-y-2 flex flex-col">
          <a href={Profile} download={Profile} className="hover:text-green-500">
            <span className="hover:text-green-500">
              Download Profile Planning unit
            </span>
          </a>
          <a
            href={Report2020}
            download={Report2020}
            className="hover:text-green-500"
          >
            <span className="hover:text-green-500">
              Download Annual Report 2020-2021
            </span>
          </a>
          <a
            href={Report2021}
            download={Report2021}
            className="hover:text-green-500"
          >
            <span className="hover:text-green-500">
              Download Annual Report 2019-2020
            </span>
          </a>
          <a href={Plan} download={Plan}>
            <span className="hover:text-green-500">
              Download NCDC Strategic Plan
            </span>
          </a>
        </div>
        <div className="my-4">
          <div className=" text-2xl font-bold" style={{ color: "#008e51" }}>
            {" "}
            National Knowledge and Skills Objectives
          </div>
          <img
            src={NationalKnoImage}
            alt="national kno"
            className="w-full border border-gray-200 my-6 h-auto"
          />
        </div>
      </div>
    </>
  );
}
