import React from "react";
import { Designation } from "./Designation";
import MaleAvatar from "assets/images/man-avatar.jpeg";
import { UPLOADS_URL } from "config";

export const PersonItem = ({
  frontViewAvatar,
  designation,
  firstName,
  lastName,
}) => {
  return (
    <>
      <div className="text-center bg-white">
        <div className="">
          <img
            src={UPLOADS_URL + "/" + frontViewAvatar || MaleAvatar}
            alt={firstName}
            style={{
              height: "30vh",
              width: "30vh",
            }}
            className="rounded-full mx-auto my-2 border-4 border-green-200"
          />
        </div>
        <div className="py-2 mx-2">
          <p className="font-bold text-xl">
            {firstName} {lastName}
          </p>
          <Designation designationId={designation} />
        </div>
      </div>
    </>
  );
};
