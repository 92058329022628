import PlaceHolder from "assets/images/placeholder.png";
import { Link, useParams } from "react-router-dom";
import { useService } from "../api/getService";
import { MDPreview, Spinner } from "components/elements";
import { UPLOADS_URL } from "config";
import { useServices } from "../api/getServices";

export const Service = () => {
  const { slug } = useParams();
  const servicesQuery = useServices();
  var initialSlug;
  if (!slug) {
    initialSlug = servicesQuery.data[0].slug;
  }
  const activeItem = initialSlug || slug;
  console.log(activeItem);

  const serviceQuery = useService({ slug: activeItem });

  console.log(serviceQuery.data);

  if (serviceQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!serviceQuery.data) return null;

  return (
    <div className="my-4">
      <div className="inline-block">
        <h1 className="text-2xl text-black font-bold">
          {serviceQuery.data.title}
        </h1>
        <div className="bg-green-700 w-full h-2 mb-4"></div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-2">
        <div className="col-span-9">
          <MDPreview value={serviceQuery.data.desc} />
          <div className="grid grid-cols-3 gap-8">
            {serviceQuery.data.images?.map((i) => (
              <img
                src={UPLOADS_URL + "/" + i.image}
                alt={i.caption || "belongs to a service"}
                className="h-32 w-auto"
              />
            ))}
          </div>
        </div>
        <div className="col-span-3 flex items-center justify-center">
          <img
            src={
              `${UPLOADS_URL}/${serviceQuery.data.processImage}` || PlaceHolder
            }
            alt="services"
            className="h-48"
          />
        </div>
      </div>
    </div>
  );
};
