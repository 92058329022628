import { axios } from "../../../lib/axios";
import { useQuery } from "react-query";

export const getResources = () => {
  return axios.get("/resources");
};

export const useResources = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["resources"],
    queryFn: () => getResources(),
  });
};
