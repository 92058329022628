import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getGalleryDetails = () => {
  return axios.get(`/pages/slug/gallery`);
};

export const useGalleryDetails = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["galleryDetails"],
    queryFn: () => getGalleryDetails(),
  });
};
