import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { MainLayout, PageHeader } from "components/layout";
import { useNotice } from "../api/getNotice";
import { useNotices } from "../api/getNotices";
import { MDPreview, Spinner } from "components/elements";
import { Circle } from "react-feather";

export const Notice = () => {
  const { slug } = useParams();

  const noticeQuery = useNotice({ slug });

  const noticesQuery = useNotices();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (noticeQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (!noticeQuery.data) return null;

  return (
    <MainLayout>
      <PageHeader header="public notice" />

      <div className="mx-4 lg:mx-20 my-2">
        <div className="grid grid-cols-1 gap-8">
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <MDPreview value={noticeQuery.data.desc} />
              <div className="w-3/4">
                <p className="text-xl font-semibold text-green-700 my-2 uppercase">
                  Previous Notices
                </p>
                <ul className="bg-gray-100 rounded-md p-4">
                  {noticesQuery.data?.slice(0, 6).map((notice, index) => (
                    <li key={index} className="">
                      <p className="">
                        <Link
                          to={`/notices/${notice.slug}`}
                          className="flex flex-row space-x-1 items-baseline hover:underline my-4"
                        >
                          <Circle className="h-3 font-bold" /> {notice.title}
                        </Link>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
