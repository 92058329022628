import { Text } from "components/elements";
import Skeleton from "react-loading-skeleton";
import { useMultimedia } from "../api/getMultimedia";

export const Multimedia = () => {
  const multimediaQuery = useMultimedia();

  if (multimediaQuery.isLoading) {
    return (
      <div>
        <Skeleton height={180} width={250} />
      </div>
    );
  }

  if (!multimediaQuery.data) return null;

  return (
    <>
      <div className="shadow-lg mt-4 flex items-center border border-red-200 h-64">
        <div className="md:px-1">
          <div className="p-1">
            <iframe
              className="rounded w-full h-40"
              src="https://www.youtube.com/embed/uRKKJOuVq7I"
              title="NCDC trains 920 secondary teachers"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>

            <div className="col-span-1 m-2 mx-4 md:mt-0">
              <div>
                <p className="font-bold text-sm">
                  <Text size="md">NCDC trains 920 secondary teachers</Text>
                </p>
              </div>
              <div className="mt-2">
                <p className="text-green-700 hover:underline cursor-pointer">
                  <a href="https://www.youtube.com/c/NCDCUgEduc">
                    <span className="text-xs">More NCDC videos</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
