import React, { useEffect } from "react";
import { Clock, MapPin, Phone } from "react-feather";
import { MainLayout, PageHeader } from "components/layout";
import { CreateMessage } from "../components/CreateMessage";
import { useAbout } from "../api/getAbout";
import { useContactDetails } from "../api/getPageDetails";
import { UPLOADS_URL } from "config";

export const Contact = () => {
  const aboutQuery = useAbout();
  const detailsQuery = useContactDetails();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  // console.log(aboutQuery.data);

  return (
    <MainLayout page="contact">
      <div className="bg-gray-50">
        {/* <PageHeader header="Contact Us" /> */}
        <PageHeader
          header={detailsQuery.data?.title || "Undefined"}
          desc={detailsQuery.data?.desc}
          backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
        />
        <div className="py-8 mx-8 lg:mx-20 flex justify-center">
          <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
            <div
              className="flex items-center bg-white gap-x-2 rounded-md p-8 text-center 
              shadow-md shadow-gray-300 hover:shadow-xl hover:shadow-gray-300"
            >
              <div className="mx-4">
                <Clock
                  className=" font-extrabold"
                  style={{ color: "#008e51" }}
                />
              </div>
              <div className="text-left">
                <h2 className="mb-2 font-extrabold text-md">Open hours</h2>
                <p className="text-md">
                  {aboutQuery.isLoading ? "..." : aboutQuery.data.openHours}
                </p>
              </div>
            </div>
            <div
              className="flex items-center bg-white gap-x-2 rounded-md p-8 text-center 
            shadow-md shadow-gray-300 hover:shadow-xl hover:shadow-gray-300"
            >
              <div className="mx-4">
                <MapPin
                  className=" font-extrabold"
                  style={{ color: "#008e51" }}
                />
              </div>
              <div className="text-left">
                <h2 className="mb-2 text-md font-extrabold ">Get Direction</h2>
                <p className="text-md">
                  {aboutQuery.isLoading ? "..." : aboutQuery.data.address}
                </p>
              </div>
            </div>
            <div className="flex items-center bg-white gap-x-2 rounded-md p-8 text-center shadow-md shadow-gray-300 hover:shadow-xl hover:shadow-gray-300">
              <div className="mx-4">
                <Phone className="" style={{ color: "#008e51" }} />
              </div>
              <div className="text-left">
                <h2 className="font-extrabold text-md">Reach Us</h2>
                <p className="text-md">
                  {aboutQuery.isLoading ? "..." : aboutQuery.data.phone}
                </p>
                <p className="text-md">
                  {aboutQuery.isLoading ? "..." : aboutQuery.data.email}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-8 md:mx-20 flex justify-center">
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="w-full lg:h-96 lg:w-96">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7445148433744!2d32.6320821145665!3d0.34850266407159003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177db9823d00d155%3A0x599912900faaf710!2sNational%20Curriculum%20Development%20Centre!5e0!3m2!1sen!2sug!4v1652995281165!5m2!1sen!2sug"
                  frameBorder="0"
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  className="w-full h-full"
                  title="map"
                ></iframe>
              </div>
              <div className="h-80 w-80 py-4">
                <p className="font-extrabold text-md">Send us a message</p>
                <CreateMessage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
