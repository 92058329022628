import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import KwiriAvatar from "assets/profiles/Kwiri Stephen 2.jpg";

export const Finance = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader header="Finance" to="/management" parent="Management" />

      <div className="mx-8 lg:mx-20 my-12 px-2">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <p className="my-2 font-semibold">Functions</p>
            <ul className="list-disc my-2">
              <li>
                Monitors and provides technical expertise in the accounting,
                treasury management, and financial reporting
              </li>
              <li>
                Coordinates the design and development of the Finance Policy
                Manual, policies and procedures in finance
              </li>
              <li>
                Prepares annual department work plans, budgets, investment plans
                for submission to the supervisor
              </li>
              <li>
                Guides the Budgetary Committee during the budgeting process
              </li>
              <li>Ensures effective management of the pay roll</li>
            </ul>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={KwiriAvatar}
              alt="Kwiri Stephen"
              className="h-60 w-60 rounded-full border-4 border-green-400 mx-auto"
              loading="lazy"
            />
            <p className="text-2xl mt-2 text-center">Kwiri Stephen</p>
            <p className=" text-center">Manager Finance</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
