import React from "react";
import { Anchor } from "react-feather";
import Skeleton from "react-loading-skeleton";
import { useMilestones } from "../api/getMilestones";

export const KeyMileStones = () => {
  const milestonesQuery = useMilestones();
  if (milestonesQuery.isLoading) {
    return (
      <div className="space-y-3">
        <div>
          <Skeleton height={80} width={320} />
        </div>
        <div>
          <Skeleton height={80} width={320} />
        </div>
        <div>
          <Skeleton height={80} width={320} />
        </div>
        <div>
          <Skeleton height={80} width={320} />
        </div>
        <div>
          <Skeleton height={80} width={320} />
        </div>
      </div>
    );
  }

  if (!milestonesQuery.data) return null;

  return (
    <>
      <div className="flex justify-between mx-2">
        <h1 className="font-extrabold">
          <p className="text-xl">Key Milestones</p>
        </h1>
      </div>
      <div className="m-2">
        {milestonesQuery.data?.slice(0, 5).map((item, index) => (
          <div key={index}>
            <div className="grid grid-cols-12 bg-green-50 md:my-4 my-4 py-2 px-2 rounded-md">
              <div className="hidden md:col-span-2 md:flex justify-center items-center">
                <div className="rounded-full h-14 w-14 bg-green-100 flex items-center">
                  <Anchor className="text-green-700 h-6 mx-auto" />
                </div>
              </div>
              <div className="col-span-12 md:col-span-10 py-3">
                <p style={{ color: "#008e51" }}>
                  <span className="text-md">{item.title}</span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
