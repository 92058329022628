import React from "react";

import OfficeOfDirectorImage from "assets/organogram/NCDC - Organogram 1.png";
import financeImage from "assets/organogram/NCDC - Organogram 2.png";
import CurrInsImage from "assets/organogram/NCDC - Organogram 3.png";
import CurrInsTwo from "assets/organogram/NCDC - Organogram 4.png";
import StandAloneUnits from "assets/organogram/NCDC - Organogram 5.png";

export function Organogram() {
  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide relative"
        data-bs-interval="false"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
        </div>
        <div className="carousel-inner relative w-full overflow-hidden">
          <div className="carousel-item active float-left w-full">
            <img
              src={OfficeOfDirectorImage}
              className="block w-full"
              alt="office of director"
              loading="lazy"
            />
          </div>
          <div className="carousel-item float-left w-full">
            <img src={financeImage} className="block w-full" alt="Camera" />
          </div>
          <div className="carousel-item float-left w-full">
            <img
              src={CurrInsImage}
              className="block w-full"
              alt="Directorates"
              loading="lazy"
            />
          </div>
          <div className="carousel-item float-left w-full">
            <img
              src={CurrInsTwo}
              className="block w-full"
              alt="Directorate"
              loading="lazy"
            />
          </div>
          <div className="carousel-item float-left w-full">
            <img
              src={StandAloneUnits}
              className="block w-full"
              alt="Directorates"
              loading="lazy"
            />
          </div>
        </div>
        <button
          className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon bg-green-500 inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className=" carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon bg-green-500 inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}
