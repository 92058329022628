import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getSubDirectorateById = ({ subDirectorateId }) => {
  return axios.get(`/sub-directorates/${subDirectorateId}`);
};

export const useSubDirectorateById = ({ subDirectorateId, config }) => {
  return useQuery({
    ...config,
    queryKey: ["sub_directorate", subDirectorateId],
    queryFn: () => getSubDirectorateById({ subDirectorateId }),
  });
};
