import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import DefaultAvatar from "assets/images/man-avatar.jpeg";

export const Documentation = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout page="management">
      <PageHeader
        header="Document and Library Services"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <div className="my-2">
              <p className="font-semibold my-2">Library Services</p>
              <p>
                The Documentation and Library Services is a department under the
                Directorate of Research Consultancy and Library Services with
                its function of providing relevant and up-to-date information
                for Curriculum development. It consists of the main library
                (reading space), the front/service desk, and the Librarian’s
                office which has the reserve, special collection, and the
                reference sections.
                <br />
                <br />
                It has a collection of about 7,900 books including syllabi for
                Pre-Primary, Secondary and BTVET levels; textbooks for different
                subjects; UNEB Past papers; various Government publications;
                Newspaper dailies; local language Orthographies; and other
                reference materials. The Library subscribes to and is a member
                to the Consortium of Uganda University Libraries (CUUL) and this
                enables it have access to at least 32 Electronic Databases. It
                has a seating capacity of 15 seats and is manned by two
                Professionals i.e., the Librarian Mrs. Irene Nakayenga
                Sserunjogi and a Library Assistant Mr. Luke Erismus Bukenya.
              </p>
            </div>
            <div>
              <p className="font-semibold my-2">Services offered</p>
              <p>
                Library Services offered include: providing circulation services
                to staff, Current Awareness Services, Reference Services, User
                education and Training, Issuance of the ISBN & ISSN to the
                Production unit, binding newspapers and book repair,
                reprographic services, providing space for small capacity
                trainings and meetings, assisting in the acquisition of
                Copyright for NCDC publications, and Selective Dissemination of
                Information.
              </p>
            </div>
            <div className="my-2">
              <p className="font-semibold my-2">
                Eligibility to use library and its resources
              </p>
              <p>
                All NCDC staff are eligible to use the library and its services.
                Readers from outside the Centre can also use the library but are
                not allowed to borrow and take out information resources.{" "}
              </p>
            </div>
            <div className="my-2">
              <p className="font-semibold my-2">
                Publication of the Curriculum Tree magazine
              </p>
              <p>
                The Librarian is the Chief Editor of the Curriculum Tree
                Magazine, a biannual publication of the Centre that aims at
                informing society what we do.
              </p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold">Contact</p>
              <p>P. O. Box 7002, Kampala</p>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <p>irene.nakayenga@ncdc.go.ug</p>
                  <p>insserunjogi@gmail.com</p>
                  <p>+256-752 582112 / +256-776 058596</p>
                </div>
                <div className="space-y-2">
                  <p>luke.bukenya@ncdc.go.ug</p>
                  <p>lbukenya007@gmail.com</p>
                  <p>+256 701 130924 / +256 782 084250</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={DefaultAvatar}
              alt="avatar"
              className="h-60 w-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2 text-center">
              <>Name</>
            </p>
            <p className="text-center">Title</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
