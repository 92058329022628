import React, { useEffect, useState } from "react";
import { MainLayout, PageHeader } from "components/layout";
import { useFaqs } from "../api/getFaqs";

import { MDPreview, Spinner } from "components/elements";
import { useCategories } from "../api/getCategories";
import { useFaqDetails } from "../api/getPageDetails";
import { UPLOADS_URL } from "config";

export const FAQs = () => {
  const detailsQuery = useFaqDetails();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const faqsQuery = useFaqs();
  const [activeBtn, setActiveBtn] = useState(0);

  const categories = [
    "ECCE",
    "Primary Education",
    "Secondary Education",
    "BTVET",
  ];

  if (faqsQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (!faqsQuery.data) {
    return (
      <div className="w-1/2 my-12">
        <p className="my-4 mx-10 text-green-800">
          No FAQs available at the moment.
          <br />
        </p>
      </div>
    );
  }

  var faqCategory = faqsQuery.data.filter((faq) => {
    return faq.category === categories[activeBtn];
  });
  console.log(faqCategory);

  return (
    <MainLayout page="faq">
      <PageHeader
        header={detailsQuery.data?.title || "Undefined"}
        desc={detailsQuery.data?.desc}
        backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
      />
      <div className="mx-8 lg:mx-20 my-12">
        <ul
          className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
          id="tabs-tab"
          role="tablist"
        >
          {categories.map((name, index) => (
            <li key={index} className="nav-item" role="presentation">
              <button
                className={`nav-link block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 
                ${
                  activeBtn === index
                    ? `border-b-2 border-green-400 py-3 hover:text-green-500`
                    : `py-3 hover:text-green-500`
                } 
                hover:border-transparent hover:bg-gray-100`}
                onClick={() => setActiveBtn(index)}
              >
                {name}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="tabs-tabContent">
          <div
            className={`tab-pane fade show active`}
            role="tabpanel"
            aria-labelledby="tabs-home-tab"
          >
            <div className="accordion" id="accordionExample">
              {faqCategory.map((faq, index) => (
                <div
                  key={index}
                  className="accordion-item bg-white border border-gray-200"
                >
                  <h2 className="accordion-header mb-0" id="headingTwo">
                    <button
                      className=" collapsed relative flex justify-between  items-center w-full py-4 px-5 text-base text-gray-800 text-left  bg-white border-0 rounded-none transition focus:outline-none hover:text-green-400"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseTwo${faq._id}`}
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <h1 className="text-gray-800 hover:text-green-400">
                        {" "}
                        {faq.question}
                      </h1>
                      <svg
                        data-accordion-icon
                        className="w-6 h-6 rotate-270 shrink-0"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </h2>
                  <div
                    id={`collapseTwo${faq._id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body py-4 px-5 text-md flex flex-wrap">
                      <MDPreview value={faq.answer} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {faqCategory.length === 0 && (
              <div className="">
                <p className="text-xl text-center font-bold my-4">
                  No Faqs available
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
