import { Form, InputField } from "../../../components/Form";
import * as z from "zod";

import { useCreateContact } from "../api/createMessage";
import { SpinnerCircular } from "spinners-react";

const schema = z.object({
  email: z.string().min(1, "Required"),
});

export const JoinMailingList = () => {
  const joinMailingListMutation = useCreateContact();

  return (
    <>
      <Form
        id="join-mailing-list"
        onSubmit={async (values) => {
          await joinMailingListMutation.mutateAsync({ data: values });
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              placeholder="Email Address"
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <button
              type="submit"
              className="text-white p-2"
              style={{ backgroundColor: "#008e51" }}
            >
              <span className="text-md">
                {joinMailingListMutation.isLoading
                  ? "Loading...."
                  : "Subscribe Now"}
              </span>
            </button>
          </>
        )}
      </Form>
    </>
  );
};
