import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import UnderConstruction from "assets/images/under-construction.svg";
import { UPLOADS_URL } from "config";
import { useTendersDetails } from "../api/getPageDetails";

export const TendersBids = () => {
  const detailsQuery = useTendersDetails();
  console.log(detailsQuery);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout page="opportunity">
      <PageHeader
        header={detailsQuery.data?.title || "Undefined"}
        desc={detailsQuery.data?.desc}
        backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
      />
      <div className="mx-8 lg:mx-24">
        <h1 className="text-xl font-extrabold my-8">Tenders & Bids</h1>
        <div className="h-100 flex flex-col items-center shadow-lg p-4 mt-2 md:p-4 md:py-2">
          <div className="text-2xl text-green-600">
            This Feature is still under development!
          </div>
          <img
            src={UnderConstruction}
            alt="under construction"
            className="h-80"
          />
        </div>

        {/* {tenderList.map((tender, index) => (
          <div
            className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12 my-3 md:my-5
        shadow-lg p-4 gap-4 rounded-md justify-items-center"
          >
            <div className="col-span-1 flex justify-center items-center">
              <FileText className="w-12 h-12 md:w-24 md:h-24 text-gray-500" />
            </div>
            <div className="col-span-1 md:col-span-5 lg:col-span-8">
              <p className="font-semibold">{tender.title}</p>
              <p className="my-2">{tender.description}</p>
              <div className="flex flex-row space-x-2">
                <Menu />
                <p>146 kb</p>
              </div>
            </div>
            <div className="col-span-1 md:col-span-3 mx-auto text-white font-bold">
              <button className="px-3 py-2 bg-green-600 w-full rounded-md my-2 hover:bg-green-800">
                <div className="grid grid-cols-12">
                  <div className="col-span-2">
                    <Eye />
                  </div>
                  <p className="col-span-10 text-center">View</p>
                </div>
              </button>
              <button className="px-3 py-2 bg-green-600 w-full rounded-md my-2 hover:bg-green-800">
                <div className="grid grid-cols-12">
                  <div className="col-span-2">
                    <Download />
                  </div>
                  <div className="col-span-10 text-center">Download (PDF)</div>
                </div>
              </button>
            </div>
          </div>
        ))}
        <div className="my-4 mx-auto w-full flex items-center justify-center text-white">
          <button className="p-2 bg-green-600 mx-2 rounded-md w-12 h-12 hover:bg-green-800">
            <ArrowLeft className="mx-auto" />
          </button>
          <button className="p-2 bg-green-600 mx-2 rounded-md w-12 h-12 hover:bg-green-800">
            1
          </button>
          <button className="p-2 bg-gray-600 mx-2 rounded-md w-12 h-12 text-black hover:bg-gray-800 hover:text-white">
            ...
          </button>
          <button className="p-2 bg-green-600 mx-2 rounded-md w-12 h-12 hover:bg-green-800">
            <ArrowRight className="mx-auto" />
          </button>
        </div> */}
      </div>
    </MainLayout>
  );
};
