import React from "react";
import { ArrowRight } from "react-feather";
import { Link } from "react-router-dom";

export const CarouselItem = ({ to, src, title, desc, time }) => {
  return (
    <div>
      <Link to={to} className="flex justify-center my-4">
        <div
          className="bg-gray-100 flex-col w-full items-center overflow-hidden 
       px-4 py-3 space-y-2 border shadow-xl hover:shadow-2xl cursor-pointer text-center"
        >
          <div className="h-48 w-4/5 mx-auto overflow-hidden">
            <img
              className="rounded-md transition"
              src={src}
              alt={title}
              loading="lazy"
            />
          </div>
          <div className="space-y-1">
            <div className="h-8 overflow-hidden">
              <h2 className="py-2 font-bold uppercase transition hover:text-green-500 wrap-text">
                <p className="text-md">{title}</p>
              </h2>
            </div>
            <p className="text-start py-2 select-none text-sm text-gray-600 h-20">
              <span className="text-md">{desc}</span>
            </p>
            {/* <div className="">
              <p className="text-gray-400">
                <p className="text-md">Posted: {time}</p>
              </p>
            </div> */}
          </div>
          <div className="flex justify-end">
            <button className="text-gray-500 flex hover:text-green-500 cursor-pointer">
              <p className="text-md">Read more</p> &nbsp; <ArrowRight />
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
};
