import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getResourcesDetails = () => {
  return axios.get(`/pages/slug/resources`);
};

export const useResourcesDetails = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["resourcesDetails"],
    queryFn: () => getResourcesDetails(),
  });
};
