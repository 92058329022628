import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getDesignation = ({ designationId }) => {
  return axios.get(`/designations/${designationId}`);
};

export const useDesignation = ({ designationId, config }) => {
  return useQuery({
    ...config,
    queryKey: ["designation", designationId],
    queryFn: () => getDesignation({ designationId }),
  });
};
