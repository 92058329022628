import { MDPreview, Spinner } from "components/elements";
import { MainLayout, PageHeader } from "components/layout";
import React from "react";
import { useParams } from "react-router-dom";
import formatDate from "utils/formatDate";
import { formatHTMLToFirstSentence } from "utils/formatHTMLToFirstSentence";
import { useSearch } from "../api/getSearchResults";

export const Search = () => {
  const q = localStorage.getItem("q");

  const searched = useParams({ q });

  const searchQuery = useSearch({ q });

  if (searchQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (!searchQuery.data) return null;

  return (
    <>
      <MainLayout>
        <PageHeader header="Search" />
        <div className="my-2 mx-2 md:px-20">
          <div className="md:my-2">
            <div className="p-2 md:hidden">
              <p className="text-md">
                You searched for "{searched.q}" returned{" "}
                {searchQuery.data.totalResultsCount} result(s)
              </p>
            </div>
            <div className="md:flex mt-5 gap-5">
              <div className="flex flex-col gap-5 md:mt-14 items-center mb-5">
                <div className="border border-t-2 border-l-2 border-b-2 border-b-gray-700  border-t-gray-700 w-80 h-fit">
                  <div className="p-3 bg-green-100 border-b-2 border-gray-300 ">
                    <h1 className="mx-3">Content</h1>
                  </div>
                  <div className="p-5">
                    <ul className="flex flex-col gap-5">
                      <li>
                        <p className="text-md">
                          {" "}
                          News posts ({" "}
                          {searchQuery.data.posts &&
                            searchQuery.data.posts.length}
                          )
                        </p>
                      </li>
                      <li>
                        <p className="text-md">
                          {" "}
                          Adverts(
                          {searchQuery.data.ads && searchQuery.data.ads.length})
                        </p>
                      </li>
                      <li>
                        <p className="text-md">
                          Careers (
                          {searchQuery.data.careers &&
                            searchQuery.data.careers.length}
                          )
                        </p>
                      </li>
                      <li>
                        <p className="text-md">
                          Management (
                          {searchQuery.data.directorates &&
                            searchQuery.data.directorates.length}
                          )
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="border border-t-2 border-l-2 border-b-2 border-b-gray-700  border-t-gray-700 w-80 h-fit">
                  <div className="p-3 bg-green-100 border-b-2 border-gray-300 ">
                    <h1 className="mx-3">Resources</h1>
                  </div>
                  <div className="p-5">
                    <ul className="flex flex-col gap-5">
                      <li>
                        <p className="text-md">
                          Publications (
                          {searchQuery.data.resources &&
                            searchQuery.data.resources.length}
                          )
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <div className="hidden md:flex p-2 mb-4">
                  <p className="text-md">
                    {" "}
                    You searched for "{searched.q}" returned{" "}
                    {searchQuery.data.totalResultsCount} result(s)
                  </p>
                </div>
                <div className="flex flex-col gap-3 mx-2">
                  {searchQuery.data.totalResults &&
                    searchQuery.data.totalResults.map((p) => (
                      <div
                        key={p.id}
                        className="px-2 py-3 border border-l-8 border-l-gray-700 border-t-gray-700 h-fit flex flex-col gap-1"
                      >
                        <h1 className="font-semibold">
                          <p className="text-md">{p.title ? p.title : ""}</p>
                        </h1>
                        <p className="text-sm">
                          <MDPreview
                            value={
                              p.desc ? formatHTMLToFirstSentence(p.desc) : ""
                            }
                          />
                        </p>
                        <p className="text-sm text-gray-500 ">
                          {formatDate(p.createdAt)}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};
