import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getQuickLinks = () => {
  return axios.get("/quick-links");
};

export const useQuickLinks = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["quicklinks"],
    queryFn: () => getQuickLinks(),
  });
};
