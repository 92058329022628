import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getMilestones = () => {
  return axios.get("/milestones");
};

export const useMilestones = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["key-milestones"],
    queryFn: () => getMilestones(),
  });
};
