import MaleAvatar from "assets/images/man-avatar.jpeg";
import { PersonItem } from "./PersonItem";
import { useManagement } from "../api/getManagement";
import { UPLOADS_URL } from "config";

export function Management() {
  const membersQuery = useManagement();
  // if (membersQuery.isLoading) {
  //   return (
  //     <div className="w-full h-48 flex justify-center items-center">
  //       <Spinner size="lg" />
  //     </div>
  //   );
  // }

  if (!membersQuery.data) return null;

  return (
    <>
      <div className="mx-auto mt-10">
        {membersQuery.data.map((member, index) => {
          if (member.designation.slug === "director")
            return (
              <div className="flex flex-col my-4">
                <div className="text-center">
                  <div className="">
                    <img
                      src={
                        UPLOADS_URL + "/" + member.frontViewAvatar || MaleAvatar
                      }
                      alt={member.firstName}
                      className="rounded-full mx-auto h-28 w-28 my-2 border-2 border-green-500"
                    />
                  </div>
                  <div className="py-2 mx-2">
                    <p className="font-bold text-xl">
                      {member.firstName} {member.lastName}
                    </p>
                    <p>{member.designation?.title}</p>
                  </div>
                </div>
              </div>
            );
        })}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {membersQuery.data?.map((person, index) => (
          <PersonItem
            key={index}
            designationId={person.designation}
            {...person}
          />
        ))}
      </div>
    </>
  );
}
