import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getNotice = ({ slug }) => {
  return axios.get(`/notices/slug/${slug}`);
};

export const useNotice = ({ slug, config }) => {
  return useQuery({
    ...config,
    queryKey: ["notice", slug],
    queryFn: () => getNotice({ slug }),
  });
};
