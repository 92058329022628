import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";

export const createMessage = ({ data }) => {
  return axios.post(`/messages`, data);
};

createMessage.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export const useCreateMessage = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newMessage) => {
      await queryClient.cancelQueries("messages");

      const previousMessages = queryClient.getQueryData("messages");

      queryClient.setQueryData("messages", [
        ...(previousMessages || []),
        newMessage.data,
      ]);

      return { previousMessages };
    },
    onError: (_, __, context) => {
      if (context?.previousMessages) {
        queryClient.setQueryData("messages", context.previousMessages);
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("messages");
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: createMessage,
  });
};
