import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export const CalendarCards = () => {
  const [date, setDate] = useState(new Date());

  const items = [
    {
      title: "The best of NCDC",
      date: "Kumi 18-05-2022",
      span: "",
    },
    {
      title: "New Curriculum Trainings",
      date: "Kampala 20-08-2022",
      span: "",
    },
    {
      title: " Curriculum Lauch",
      date: "Minister's village Ntinda 12-03-2022",
      span: "12-03-2022",
    },
  ];

  return (
    <>
      <div className="h-[32rem] bg-white grid grid-cols-5">
        <div className="col-span-3 h-full bg-green flex justify-center items-center">
          <Calendar
            onChange={setDate}
            value={date}
            calendarType={"US"}
            className="h-80 w-96 px-2 py-5"
          />
        </div>
        <div className="col-span-2 h-[32rem] bg-white flex flex-col gap-2 justify-center  items-start p-5">
          {items.map((item) => (
            <div className="cursor-pointer p-2 flex flex-col justify-center items-center h-44 w-56 rounded-xl bg-blue-400">
              <h1 className="text-white font-light text-lg">{item.title}</h1>
              <p className="text-white text-sm font-light  flex flex-col items-center wrap">
                {item.date}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
