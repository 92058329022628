import { Form, InputField, UploadField } from "../../../components/Form";
import * as z from "zod";

import { useCreateApplication } from "../api/createApplication";
import { Send } from "react-feather";
import { useState } from "react";

const schema = z.object({
  firstName: z.string().min(1, "Required"),
  lastName: z.string().min(1, "Required"),
  phone: z.string().min(1, "Required"),
  email: z.string().min(1, "Required"),
});

export const ApplicationForm = () => {
  const [resume, setResume] = useState(null);

  const CreateApplicationMutation = useCreateApplication();

  return (
    <>
      <Form
        id="create-message"
        onSubmit={async (values) => {
          const { firstName, lastName, phone, email } = values;
          const bodyFormData = new FormData();
          bodyFormData.append("resume", resume);
          bodyFormData.append("firstName", firstName);
          bodyFormData.append("lastName", lastName);
          bodyFormData.append("phone", phone);
          bodyFormData.append("email", email);
          await CreateApplicationMutation.mutateAsync({ data: bodyFormData });
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              placeholder="First Name"
              error={formState.errors["firstName"]}
              registration={register("firstName")}
            />
            <InputField
              placeholder="Last Name"
              error={formState.errors["lastName"]}
              registration={register("lastName")}
            />
            <InputField
              placeholder="Phone Number"
              error={formState.errors["phone"]}
              registration={register("phone")}
            />
            <InputField
              placeholder="Email Address"
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <UploadField
              error={formState.errors["resume"]}
              onChange={(e) => {
                setResume(e.target.files[0]);
              }}
            />
            <div className="mb-2">
              <button
                type="submit"
                className="mt-3 flex flex-row space-x-2 items-center px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-200 focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
              >
                <Send /> <span>Submit</span>
              </button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};
