import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import AngelaAvatar from "../../../assets/profiles/Angela Kyagaba 2.jpg";

export const QualityPublications = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Quality Assurance & Publication"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <div className="m-2 text-justify">
              <p className="my-4 font-semibold">What we do?</p>
              <ul className="my-4 list-disc mx-2 space-y-2">
                <li>
                  Building of staff capacity in the area of quality management.
                </li>
                <li>
                  Coordinating the setting of standards during curriculum
                  development processes.
                </li>
                <li>
                  Evaluating materials from academic departments and writing
                  recommendations on improvement.
                </li>
                <li>
                  Evaluating academic materials from the public and writing
                  recommendations on their suitability for use in Uganda’s
                  educational institutions.
                </li>
                <li>
                  Evaluating for certification other curriculum materials for
                  use in Uganda’s educational institutions.
                </li>
                <li>
                  Editing, proof reading and styling materials from academic
                  departments and the public.
                </li>
              </ul>
              <p className="my-4 font-semibold underline">
                PARAMETERS FOR EVALUATING MATERIALS
              </p>
              <ul className="my-4 list-decimal mx-2 space-y-2">
                <li>Conformity to the Syllabus.</li>
                <li>
                  Content/Subject matter.
                  <ul className="list-disc mx-4">
                    <li>Relevance of the subject</li>
                    <li>Accuracy of facts & concepts</li>
                    <li>Appropriateness to the target users</li>
                    <li>Logical Presentation</li>
                    <li>Promotion of Ugandan local issues</li>
                    <li>Originality.</li>
                  </ul>
                </li>
                <li>Appropriateness of language to the target users.</li>
                <li>
                  Relevance of the tasks/activities in view of the target users.
                </li>
                <li>Quality and relevance of illustrations or pictures.</li>
                <li>
                  Sensitivity.
                  <ul className="list-disc mx-4">
                    <li>Persons and events portrayed.</li>
                    <li>Gender</li>
                    <li>Objectivity</li>
                    <li>National groups</li>
                  </ul>
                </li>
                <li>
                  Layout and design.
                  <ul className="list-disc mx-4">
                    <li>Binding</li>
                    <li>Quality of paper used</li>
                    <li>Font type according to the target users</li>
                    <li>Colour used according to the target users</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2 mx-auto text-center">
            <img
              src={AngelaAvatar}
              alt="Mrs. Angela Kyagaba"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Angela Kyagaba</p>
            <p className="">Manager Quality Assurance and Publishing</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
