import { axios } from "../../../lib/axios";
import { queryClient } from "../../../lib/react-query";
import { useNotificationStore } from "../../../stores/notifications";
import PropTypes from "prop-types";
import { useMutation } from "react-query";

export const createApplication = ({ data }) => {
  return axios.post(`/applications`, data);
};

createApplication.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    resume: PropTypes.string.isRequired,
  }).isRequired,
};

export const useCreateApplication = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newApplication) => {
      await queryClient.cancelQueries("applications");

      const previousApplications = queryClient.getQueryData("applications");

      queryClient.setQueryData("applications", [
        ...(previousApplications || []),
        newApplication.data,
      ]);

      return { previousApplications };
    },
    onError: (_, __, context) => {
      if (context?.previousApplications) {
        queryClient.setQueryData("applications", context.previousApplications);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("applications");
      addNotification({
        type: "success",
        title: "Application has been submitted",
      });
    },
    ...config,
    mutationFn: createApplication,
  });
};
