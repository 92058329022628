import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getMultimedia = () => {
  return axios.get(`/multimedia/latest`);
};

export const useMultimedia = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["multimedia"],
    queryFn: () => getMultimedia(),
  });
};
