import React from "react";
import { Facebook, Twitter, Youtube, Linkedin, Search } from "react-feather";
import { SearchFor } from "./SearchFor";

export default function MediaBanner() {
  return (
    <div className="">
      <div
        className="bg-gray-200 text-white p-2 relative w-full flex flex-wrap items-center justify-between lg:px-20 
			 text-md shadow-lg navbar navbar-expand-lg navbar-light"
      >
        <SearchFor />
        <div className="">
          <span className="flex md:space-x-3 space-x-1 items-center">
            <a
              href="https://ncdc.umcs.go.ug/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-700 md:text-md text-xs"
            >
              Webmail
            </a>
            <a
              href="https://app.myloft.xyz/user/login?institute=ckjl06x8mprmt0898omf4t2v4"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-700 md:text-md text-xs"
            >
              E-Library
            </a>
            <a
              href="https://facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:bg-green-200 bg-gray-400 rounded-full grid p-1 place-content-center  transition ease-in-out delay-50 duration-300"
            >
              <Facebook className="md:h-5 md:w-5 h-4 w-4 text-white hover:text-green-700  transition ease-in-out delay-50 duration-300" />
            </a>
            <a
              href="https://twitter.com/NCDCUg"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:bg-green-200 bg-gray-400 rounded-full grid p-1 place-content-center  transition ease-in-out delay-50 duration-300"
            >
              <Twitter className="md:h-5 md:w-5 h-4 w-4 text-white hover:text-green-700  transition ease-in-out delay-50 duration-300" />
            </a>
            <a
              href="https://www.youtube.com/c/NCDCUgEduc"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:bg-green-200 bg-gray-400 rounded-full grid p-1 place-content-cente  transition ease-in-out delay-50 duration-300"
            >
              <Youtube className="md:h-5 md:w-5 h-4 w-4 text-white hover:text-green-700  transition ease-in-out delay-50 duration-300" />
            </a>
            <a
              href="https://www.linkedin.com/company/national-curriculum-development-centre/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:bg-green-200 bg-gray-400 rounded-full grid p-1 place-content-center transition ease-in-out delay-50 duration-300"
            >
              <Linkedin className="md:h-5 md:w-5 h-4 w-4 text-white hover:text-green-700  transition ease-in-out delay-50 duration-300" />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
