import { axios } from "../../../lib/axios";
import { useQuery } from "react-query";

export const getGallery = () => {
  return axios.get("/gallery");
};

export const useGallery = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["gallery"],
    queryFn: () => getGallery(),
  });
};
