import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getService = ({ slug }) => {
  return axios.get(`/services/slug/${slug}`);
};

export const useService = ({ slug, config }) => {
  return useQuery({
    ...config,
    queryKey: ["service", slug],
    queryFn: () => getService({ slug }),
  });
};
