import { Text } from "components/elements";
import GalleryPlaceholder from "assets/images/speech.jpg";
import { Link } from "react-router-dom";
import { usePhotos } from "../api/getPhotos";
import { UPLOADS_URL } from "config";
import Skeleton from "react-loading-skeleton";

export const Gallery = () => {
  const photosQuery = usePhotos();

  if (photosQuery.isLoading) {
    return (
      <div>
        <Skeleton height={180} width={250} />
      </div>
    );
  }

  if (!photosQuery.data) return null;

  return (
    <>
      <div className="shadow-lg border border-red-200 mt-4 flex items-center h-64">
        <div className="md:py-1 md:px-1 p-3">
          <div className="p-1">
            <img
              src={
                UPLOADS_URL + "/" + photosQuery.data[0]?.image ||
                GalleryPlaceholder
              }
              alt={photosQuery.data[0]?.caption}
              className="bg-cover w-full rounded h-40"
            />
          </div>
          <div className="col-span-1 m-2 mx-4 md:mt-0">
            <div>
              <p className="font-bold text-sm">
                <Text size="md">{photosQuery.data[0]?.caption}</Text>
              </p>
            </div>
            <div className="mt-2">
              <Link to="/gallery">
                <p className="text-green-700 hover:underline text-xs">
                  More images in gallery
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
