import React from "react";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";

export const ShopLayout = ({ page, children }) => {
  return (
    <div>
      <Navbar page={page} />
      {children}
      <Footer />
    </div>
  );
};
