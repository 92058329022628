import { axios } from '../../../lib/axios';
import { useQuery } from 'react-query';

export const getAd = ({ slug }) => {
  return axios.get(`/adverts/slug/${slug}`);
};

export const useAd = ({ slug, config }) => {
  return useQuery({
    ...config,
    queryKey: ['ad', slug],
    queryFn: () => getAd({ slug }),
  });
};
