import { MainLayout } from "components/layout";
import { PageHeader } from "components/layout";
import TableData from "./tableData";

export const TableContent = () => {
  return (
    <MainLayout>
      <PageHeader header="Approved Books" />
      <div
        className="mx-8 lg:mx-20 px-2 h-80 pr-4 my-8 scrollbar-thin scrollbar-thumb-green-700
         scrollbar-track-gray-100 scrollbar-thumb-rounded text-sm lg:text-base"
      >
        <style>{`th, td {
            padding: 10px 15px;
        }
        `}</style>
        <table className="table-auto w-full text-left relative">
          <thead className="bg-green-700 text-white sticky top-0">
            <tr>
              <th>Author Name</th>
              <th>Book Title</th>
              <th>Date of Submission</th>
              <th>Date of Approval</th>
              <th>Targeted Level</th>
            </tr>
          </thead>
          <tbody>
            {TableData.map((data) => (
              <tr className="border border-gray-200">
                <td>{data.author}</td>
                <td>{data.book}</td>
                <td>{data.date}</td>
                <td>{data.approvalDate}</td>
                <td>{data.targetLevel}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </MainLayout>
  );
};
