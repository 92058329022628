import { Package } from "react-feather";

export function Functions() {
  return (
    <>
      <div className="py-8 px-12 flex flex-col justify-center bg-slate-100">
        <p className="font-semibold my-2">
          <p className="text-2xl">Functions of NCDC.</p>
        </p>
        <ul className="text-justify">
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap md:text-base">
              <p className="text-md">
                To investigate and evaluate the need for syllabus revision and
                curriculum reform at Primary, Secondary and Tertiary levels of
                education in Pre-school education and in teacher education.
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                To initiate new syllabuses, revise existing ones, carry out
                curriculum reform, research, testing and evaluation, bring
                up-to-date and improve syllabuses for school and college
                courses.
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                To draft teaching schemes, textbooks, Teachers’ Manuals, and
                examination syllabuses in cooperation with teaching institutions
                and examining bodies.
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                To design and develop teaching aids and instructional materials
                e. We devise, test and evaluate examination questions and
                methods of examining students with other appropriate teaching
                and examining bodies and disseminate the teaching aids and
                materials.
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                To organize and conduct in-service courses of instruction for
                the acquisition of knowledge and professional skill by persons
                intending or required to teach new courses developed at the
                Centre.
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                To hold seminars and conferences on curriculum development
                projects and problems
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                To collect, compile, analyze and extract statistical information
                on curriculum and matters related to curriculum.
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                To publish information, bulletins, digests, periodicals or other
                written material concerning curriculum and other matters related
                to curriculum.
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                To disseminate and promote general and better knowledge and
                understanding of new curriculums, teaching methods and teaching
                aids.{" "}
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                {" "}
                To cause to be approved by publishers, curriculum related
                materials for use by schools and colleges and to evaluate those
                materials.
              </p>
            </div>
          </li>
          <li className="my-2 items-center bg-gray-100 px-4 md:p-2 py-4 m-2 hover:bg-gray-200 flex flex-row gap-4 w-full h-fit">
            <div className="bg-gray-800 hidden md:flex rounded-full p-2">
              <Package className="text-white" />
            </div>
            <div className="text-wrap  md:text-base">
              <p className="text-md">
                To vet and approve any international or other curriculum that is
                not developed by the Centre, to be used by a tertiary
                institution or school in Uganda, before the registration or
                licensing by the relevant authority of the tertiary institution
                or school that intends to use that curriculum.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
