import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getJobs = () => {
  return axios.get("/jobs");
};

export const useJobs = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["jobs"],
    queryFn: () => getJobs(),
  });
};
