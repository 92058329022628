import { useEffect, useState } from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import { Government } from "../components/Government";
import { Management } from "../components/Management";
import { Organogram } from "../components/Organogram";
import { UPLOADS_URL } from "config";
import { useGovernDetails } from "../api/getGovernancePage";

export function GoverningCouncil() {
  const detailsQuery = useGovernDetails();

  const [isActive, setisActive] = useState("govt");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout page="about">
      <PageHeader
        header={detailsQuery.data?.title || "Undefined"}
        desc={detailsQuery.data?.desc}
        backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
      />
      <div className="mx-8 lg:mx-20 px-2 md:mx-auto my-12">
        <div className="flex border-b-4 border-gray-300 text-xs md:text-lg">
          <button
            className={
              isActive === "govt"
                ? "mx-4 text-white font-bold bg-green-500 px-2 md:px-4 py-2"
                : "mx-4 text-green-500 hover:font-bold"
            }
            onClick={() => setisActive("govt")}
          >
            Governing Council
          </button>
          <button
            className={
              isActive === "mgt"
                ? "mx-4 text-white font-bold bg-green-500 px-4 py-2"
                : "mx-4 text-green-500 hover:font-bold"
            }
            onClick={() => setisActive("mgt")}
          >
            Management
          </button>
          <button
            className={
              isActive === "organogram"
                ? "mx-4 text-white font-bold bg-green-500 px-4 py-2"
                : "mx-4 text-green-500 hover:font-bold"
            }
            onClick={() => setisActive("organogram")}
          >
            Organogram
          </button>
        </div>
        <div>
          {isActive === "govt" && <Government />}
          {isActive === "mgt" && <Management />}
          {isActive === "organogram" && <Organogram />}
        </div>
      </div>
    </MainLayout>
  );
}
