import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import FemaleAvatar from "assets/images/female-avatar.jpeg";

export const LifeSkill = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const diploma = [
    "Mechanical engineering",
    "Civil Engineering",
    "Electrical Engineering",
    "Refrigeration and Air Conditioning",
    "Ceramics",
    "Water Engineering",
    "Information and Communication Technology",
    "Architecture",
    "Leather Technology",
    "Accountancy",
    "Marketing",
    "Business Management",
    "Public Administration and Management",
    "Procurement and Supplies Management",
    "Customs Clearing and forwarding",
    "Human Resource Management",
    "Journalism and Media Studies",
    "Hotel and institutional Catering",
    "Cosmetology",
    "Records and Information Management",
    "Secretarial and Office Administration",
    "Fashion and Garment Design",
  ];
  const certificate = [
    "Machining and Fitting",
    "Welding and Fabrication",
    "Building Construction",
    "Woodwork Technology",
    "Plumbing",
    "Painting and Decoration",
    "Electrical Installation Systems and Maintenance",
    "Electronics",
    "Computer Repair and Maintenance",
    "Performing Arts",
    "Refrigeration and Air Conditioning",
    "Agricultural Production",
    "Ceramics",
    "Water Engineering",
    "Information and Communication Technology",
    "Architecture",
    "Leather Technology",
    "Accountancy ",
    "Marketing ",
    "Business Administration",
    "Public Administration and Management",
    "Procurement and Supplies Management",
    "Clearing and Forwarding",
    "Human Resource Management",
    "Journalism and Mass Communication",
    "Hotel and Institutional Catering",
    "Cosmetology and Body Therapy",
    "Records and Information Management",
    "Secretarial and Information Management",
    "Fashion and Garment Design",
    "Guidance and Counseling",
    "Social Work",
    "Tourism and Hospitality",
  ];
  return (
    <MainLayout page="management">
      <PageHeader
        header="BTVET/LifeLong Skills and Co-curricular activities"
        to="/management"
        parent="Management"
      />

      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <div className="m-2 text-justify">
              <p>
                LLSC department is mandated to develop curricula for tertiary
                institutions for Certificate and Diploma programmes run by both
                public and private institutions. The department is developing
                and reviewing curricula to make them competence based with a
                modular approach for skills development. The new curricula will
                produce learners who are analytical, creative, and innovative.
              </p>
              <p className="font-semibold my-2">
                THE CURRICULA SO FAR DEVELOPED ARE:
              </p>
              <p className="font-semibold my-2">Technical and Vocational</p>
              <ul className="list-decimal space-y-2">
                <li className="font-semibold">
                  HIGHER NATIONAL DIPLOMA PROGRAMMES IN:
                  <ul className="font-normal list-disc space-y-1">
                    <li>Mechanical engineering</li>
                    <li>Civil Engineering</li>
                    <li>Electrical Engineering</li>
                  </ul>
                </li>
                <li className="font-semibold">
                  NATIONAL DIPLOMA PROGRAMMES IN;
                  <ul className="font-normal list-disc space-y-1 grid grid-cols-2">
                    {diploma.map((name, index) => (
                      <li key={index}>{name}</li>
                    ))}
                  </ul>
                </li>
                <li className="font-semibold">
                  NATIONAL CERTIFICATE COURSES
                  <ul className="font-normal list-disc space-y-1 grid grid-cols-2">
                    {certificate.map((name, index) => (
                      <li key={index}>{name}</li>
                    ))}
                  </ul>
                </li>
                <li className="font-semibold">
                  The LLSC department carried out a needs assessment on the
                  Community Polytechnics curriculum and has embarked on the
                  review of its courses:
                  <ul className="list-disc space-y-1 font-normal">
                    <li>Automotive Mechanics</li>
                    <li>Electrical Installation</li>
                    <li>Carpentry and Joinery</li>
                    <li>Building Construction</li>
                    <li>Hotel and Catering Services</li>
                    <li>Fashion and Garment Design</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={FemaleAvatar}
              alt="Mr. Habiyaremye John"
              className="h-60 w-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2 text-center">Alisat Gule</p>
            <p className="text-center">
              Manager BTVET/LifeLong Skills Department
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
