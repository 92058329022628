import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const searchFor = ({ q }) => {
  return axios.get(`/search?q=${q}`);
};

export const useSearch = ({ q, config }) => {
  return useQuery({
    queryKey: ["search", q],
    queryFn: () => searchFor({ q }),
    ...config,
  });
};
