import React from "react";
import { Circle } from "react-feather";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const QuickLinkItem = ({ to, link }) => {
  return (
    <li className="">
      <p className="text-md">
        <Link
          to={to}
          className="flex flex-row space-x-1 items-center hover:underline my-4"
        >
          <Circle className="h-3 font-bold" /> {link}
        </Link>
      </p>
    </li>
  );
};

QuickLinkItem.propTypes = {
  to: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
