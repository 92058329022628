import React from "react";
// import { Circle } from "react-feather";
import { QuickLinkItem } from "./QuickLinkItem";
import { useQuickLinks } from "../api/getQuickLinks.js";
import Skeleton from "react-loading-skeleton";

export const QuickLinks = () => {
  const quickLinksQuery = useQuickLinks();

  if (quickLinksQuery.isLoading) {
    return (
      <div>
        <Skeleton height={180} width={250} />
      </div>
    );
  }

  if (!quickLinksQuery.data) return null;

  return (
    <div className="w-full shadow-lg p-4 border border-red-200 md:py-2 h-48">
      <ul className="">
        {quickLinksQuery.data?.slice(0, 4).map((link) => (
          <QuickLinkItem key={link._id} to={link.url} link={link.title} />
        ))}
      </ul>
    </div>
  );
};
