import { NoticesList } from "./NoticesList";

export const NoticeBoard = () => {
  return (
    <div className="my-2 lg:my-0">
      <h1 className="text-xl font-extrabold">Notice</h1>
      <NoticesList />
    </div>
  );
};
