import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getDirectorate = ({ slug }) => {
  return axios.get(`/directorates/slug/${slug}`);
};

export const useDirectorate = ({ slug, config }) => {
  return useQuery({
    ...config,
    queryKey: ["directorate", slug],
    queryFn: () => getDirectorate({ slug }),
  });
};
