import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getServices = () => {
  return axios.get("/services");
};

export const useServices = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["services"],
    queryFn: () => getServices(),
  });
};
