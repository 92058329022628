import React from "react";
import { UPLOADS_URL } from "config";
import { useAds } from "../api/getAds";
import { Link } from "react-router-dom";
import BoundaryImage from "assets/images/boundary.png";
import Skeleton from "react-loading-skeleton";
import { Text } from "components/elements";

export const Banner = () => {
  const adsQuery = useAds();

  if (adsQuery.isLoading) {
    return (
      <div className="w-full md:h-[380px] flex justify-center items-center animate-pulse">
        <div className="bg-slate-200 h-full w-full shadow-lg">
          <div className="h-full w-full flex justify-center items-center overflow-hidden">
            <Skeleton width={2000} height={2000} />
          </div>
        </div>
      </div>
    );
  }

  if (!adsQuery.data)
    return (
      <div className="w-full md:h-[380px] flex justify-center items-center animate-pulse">
        <div className="bg-slate-200 h-full w-full shadow-lg">
          <div className="h-full w-full flex justify-center items-center">
            <Text size="lg">Unable to fetch content</Text>
          </div>
        </div>
      </div>
    );

  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide carousel-fade relative mb-4"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
        {/* display carousel indicators */}
        {adsQuery.data.map((ad, index) => {
          return (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={`carousel-indicators-item ${
                index === 0 ? "active" : ""
              }`}
              aria-current="true"
              aria-label="slide 1"
            ></button>
          );
        })}
      </div>
      <div className="carousel-inner relative w-full overflow-hidden">
        {adsQuery.data.map((ad, index) => {
          return (
            <div
              key={index}
              className={`carousel-item ${
                index === 0 ? "active" : ""
              } relative float-left w-full`}
            >
              <>
                <img
                  src={`${UPLOADS_URL}/${ad.image}`}
                  alt="banner"
                  className="brightness-75 w-full h-48 md:h-72 lg:h-full"
                  loading="lazy"
                />
                <div className="absolute top-1/2 transform -translate-y-1/2 inset-x-10 ml-10 md:ml-20 lg:ml-40 flex flex-row items-center w-7/12 md:w-4/6">
                  <img
                    src={BoundaryImage}
                    className="h-28 md:h-40 lg:h-52 w-auto"
                    alt="banner"
                    loading="lazy"
                  />
                  <div className="-mx-12 md:-mx-16 lg:-mx-24 text-white">
                    <p className="py-1 md:py-2 lg:py-4 md:text-3xl lg:text-4xl font-black">
                      {ad.title}
                    </p>
                    <div>
                      <Link
                        to={`/adverts/${ad.slug}`}
                        className="text-md mx-2 p-2 lg:px-4 lg:py-2 hover:bg-green-500"
                        style={{ backgroundColor: "#008e51" }}
                      >
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            </div>
          );
        })}
      </div>
      <button
        className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};
