import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getDirectorates = () => {
  return axios.get("/directorates");
};

export const useDirectorates = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["directorates"],
    queryFn: () => getDirectorates(),
  });
};
