import { axios } from '../../../lib/axios';
import { useQuery } from 'react-query';

export const getAds = () => {
  return axios.get('/adverts');
};

export const useAds = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['ads'],
    queryFn: () => getAds(),
  });
};
