import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import PropTypes from "prop-types";
import { useMutation } from "react-query";

export const downloadResource = ({ resourceId }) => {
  return axios.patch(`/resources/${resourceId}/download`);
};

downloadResource.propTypes = {
  resourceId: PropTypes.string.isRequired,
};

export const useDownloadResource = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context) => {
      if (context?.previousResource) {
        queryClient.setQueryData(
          ["resource", context.previousResource.id],
          context.previousResource
        );
      }
    },
    onSuccess: () => {
      addNotification({
        type: "success",
        title: "Resource Downloaded",
      });
    },
    ...config,
    mutationFn: downloadResource,
  });
};
