import React from "react";
import { MainLayout, PageHeader } from "components/layout";

export const ELearning = () => {
  const Youtube = [
    {
      video_id: "UD3ySmPaGCU",
      title: "Primary 1, Lessons for Special Needs children.",
      topic: "Addition",
    },
    {
      video_id: "fK48wvwKdLI",
      title: "Primary 1, Lessons for Special Needs children",
      topic: "Thing we use at home",
    },
    {
      video_id: "RcMNMGEKAlU",
      title: "Primary 1, Lessons for Special Needs children",
      topic: "Uganda National Symbols",
    },
    {
      video_id: "DzRdrbFh1HU",
      title: "Primary 2, Lessons for Mathematics",
      topics: "Mappings and Relations",
    },
  ];

  return (
    <MainLayout>
      <PageHeader header="E-Learning" />
      <div className="mx-8 lg:mx-20 my-12">
        <p className="my-4">Lecture Videos for various classes</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {Youtube.map((video, index) => {
            let video_link = "https://www.youtube.com/embed/".concat(
              video.video_id
            );
            return (
              <div key={index} className="w-full">
                <iframe
                  src={video_link}
                  title={video.topic}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  loading="lazy"
                ></iframe>
                <div className="space-y-2 my-2">
                  <p className="text-sm text-gray-800">{video.topic}</p>
                  <p className="text-sm text-gray-800">{video.title}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </MainLayout>
  );
};
