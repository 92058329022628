import React from "react";
import { useDesignation } from "../api/getDesignation";

export const Designation = ({ designationId }) => {
  const designationQuery = useDesignation({ designationId });

  if (designationQuery.isLoading) {
    return <div className="flex justify-center items-center">....</div>;
  }

  if (!designationQuery.data) return null;

  return <div>{designationQuery.data.title}</div>;
};
