import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getPhotos = () => {
  return axios.get("/gallery");
};

export const usePhotos = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["photos"],
    queryFn: () => getPhotos(),
  });
};
