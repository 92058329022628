import { NoticeBoard } from "./NoticeBoard";
import { QuickLinks } from "../components/QuickLinks";

export const Section = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div>
          <div className="my-2 lg:my-0">
            <p className="font-extrabold mb-2 text-xl">Quick Links</p>
            <QuickLinks />
          </div>
        </div>
        <div>
          <NoticeBoard />
        </div>
      </div>
    </>
  );
};
