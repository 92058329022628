import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getAboutDetails = () => {
  return axios.get(`/pages/slug/about-ncdc`);
};

export const useAboutDetails = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["aboutDetails"],
    queryFn: () => getAboutDetails(),
  });
};
