import { Spinner } from "components/elements";
import { useState } from "react";
import { ChevronsDown, ChevronsRight } from "react-feather";
import { Link } from "react-router-dom";
import { useDirectorates } from "../api/getDirectorates";
import { useSubDirectorateById } from "../api/getSubDirectoratesById";

export const DirectoratesList = () => {
  const directoratesQuery = useDirectorates();

  const [activeDirectorate, setActiveDirectorate] = useState(false);

  if (directoratesQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  const SubDirectorate = ({ subDirectorateId }) => {
    const subDirectorateQuery = useSubDirectorateById({ subDirectorateId });
    return (
      <li>
        <Link
          className="dropdown-item text-sm py-2 px-4 font-normal block w-full 
               whitespace-nowrap bg-transparent text-gray-700 hover:text-green-700"
          to={`/management/${subDirectorateQuery.data?.slug}`}
        >
          {subDirectorateQuery.data?.title}
        </Link>
      </li>
    );
  };

  if (!directoratesQuery.data) return null;

  return (
    <>
      {directoratesQuery.data.map((directorate) => (
        <div className="flex justify-center my-4">
          <div
            className={`${
              directorate.subDirectorates.length ? "dropdown" : ""
            } relative w-full`}
          >
            {directorate.subDirectorates.length ? (
              <>
                <button
                  className="dropdown-toggle text-sm md:text-base font-bold text-gray-500 uppercase py-8 px-6 border border-gray-200
           rounded shadow-md hover:text-green-700 hover:shadow-lg focus:shadow-lg 
           focus:outline-none focus:ring-0 active:text-green-700 active:shadow-lg 
           transition duration-150 ease-in-out w-full"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    setActiveDirectorate(!activeDirectorate);
                  }}
                >
                  <div className="flex flex-row justify-between text-left items-center">
                    <div className="w-4/5">
                      <p>{directorate.title}</p>
                    </div>
                    <div className="">
                      {activeDirectorate ? (
                        <ChevronsDown className="text-green-700" />
                      ) : (
                        <ChevronsRight className="text-green-700" />
                      )}
                    </div>
                  </div>
                </button>
                <ul
                  className="dropdown-menu absolute hidden bg-white z-50 float-left py-2 
           list-none text-left rounded-md shadow-lg mt-1 m-0 border-none w-full"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {directorate.subDirectorates?.map((depart, index) => (
                    <SubDirectorate key={index} subDirectorateId={depart._id} />
                  ))}
                </ul>
              </>
            ) : (
              <Link to={`/management/${directorate.slug}`}>
                <button
                  className="text-sm md:text-base font-bold text-gray-500 uppercase py-8 px-6 border border-gray-200
          rounded shadow-md hover:text-green-700 hover:shadow-lg focus:shadow-lg 
          focus:outline-none focus:ring-0 active:text-green-700 active:shadow-lg 
          transition duration-150 ease-in-out w-full"
                  type="button"
                >
                  <div className="flex flex-row justify-between text-left items-center">
                    <div className="w-4/5">
                      <p>{directorate.title}</p>
                    </div>
                    <div className="">
                      <ChevronsRight className="text-green-700" />
                    </div>
                  </div>
                </button>
              </Link>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
