import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "components/elements";
import { MainLayout, PageHeader } from "components/layout";
import { UPLOADS_URL } from "config";
// import formatDate from "utils/formatDate";
import { formatHTMLToFirstSentence } from "utils/formatHTMLToFirstSentence";
import { usePosts } from "../api/getPosts";

export const Posts = () => {
  const postsQuery = usePosts();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (postsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  console.log(postsQuery.data);

  if (!postsQuery.data) return null;

  return (
    <MainLayout>
      <PageHeader header="News" />
      <div className="grid grid-cols-2 gap-4 mx-20 my-20">
        {postsQuery.data.map((post, index) => (
          <Link key={index} to={`/posts/${post._id}`} className="my-5">
            <div className="card bg-white h-[20rem] overflow-hidden rounded-xl p-6 space-y-4 border hover:shadow-xl cursor-pointer">
              <div className="space-y-1">
                <h2 className=" font-semibold text-xl transition hover:text-cyan-300">
                  {post.title}
                </h2>
                <p className=" text-sm select-none">
                  {formatHTMLToFirstSentence(post.desc)}
                </p>
                {/* <p className=" font-bold">{formatDate(post.createdAt)}</p> */}
              </div>
              <img
                className="w-full rounded-md transition hover:bg-cyan-300"
                src={`${UPLOADS_URL}/posts/resized/${post.image}`}
                alt={post.title}
              />
            </div>
          </Link>
        ))}
      </div>
    </MainLayout>
  );
};
