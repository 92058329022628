import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import FemaleAvatar from "../../../assets/images/female-avatar.jpeg";

export const IctDepartment = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="ICT Department"
        to="/management"
        parent="Management"
      />
      <div className="mx-8 lg:mx-20 my-12 px-2">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <div className="my-4">
              <p className="my-4 text-gray-300">
                A word from Mrs. Enid Nyendwoha goes here.
              </p>
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={FemaleAvatar}
              alt="Ms Kemizano Rosert"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Ms Kemizano Rosert</p>
            <p className="">ICT Department</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
