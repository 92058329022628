/** @format */

import { About, GoverningCouncil } from "../features/about";
import { Calendar } from "../features/calendar";
import { Career, Careers } from "../features/careers";
import { Contact } from "../features/contact";
import { FAQs } from "../features/faq";
import { Services } from "../features/services";
import { Cart, Checkout, Resources } from "../features/resources";
import { Post } from "../features/blog";
import { MediaPress, Advert, Notice } from "../features/home";
import { Posts } from "../features/blog";
import {
  CurriculumInstruction,
  Documentation,
  EarlyChildhoodCare,
  Finance,
  HumanResource,
  LiteratureBureau,
  OfficeOfDirector,
  PrimaryDepartment,
  QualityPublications,
  ResearchConsultancy,
  SpecialNeeds,
  ScienceTechEquipment,
  PrintProduction,
  LifeSkill,
  Pedagogy,
  ManagerAcademic,
  SecondaryDepartment,
  IctDepartment,
  Communications,
  Planning,
} from "../features/directorate/pages";
import { Management } from "../features/directorate";
import { NotFound } from "../features/notfound";
import { ELearning } from "../features/eLearning";
import { TableContent } from "features/services/components/body/table/tableContent";
import { Gallery } from "features/gallery/routes/Gallery";
import { Search } from "features/search";
import { TendersBids } from "features/tenders/routes";
import { Directorate } from "features/directorate";

export const publicRoutes = [
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/e-learning",
    element: <ELearning />,
  },
  {
    path: "/about/governance",
    element: <GoverningCouncil />,
  },
  // {
  //   path: "/about/ncdc-functions",
  //   element: <NCDCFunctions />,
  // },
  { path: "/management", element: <Management /> },
  { path: "/management/office-of-director", element: <OfficeOfDirector /> },
  { path: "/management/finance", element: <Finance /> },
  {
    path: "/management/research-consultancy",
    element: <ResearchConsultancy />,
  },
  { path: "/management/human-resources", element: <HumanResource /> },
  { path: "/management/planning", element: <Planning /> },
  { path: "/management/communications", element: <Communications /> },
  {
    path: "/management/curriculum-instruction",
    element: <CurriculumInstruction />,
  },
  { path: "/management/primary-department", element: <PrimaryDepartment /> },
  {
    path: "/management/secondary-department",
    element: <SecondaryDepartment />,
  },
  { path: "/management/literature-bureau", element: <LiteratureBureau /> },
  {
    path: "/management/quality-publications",
    element: <QualityPublications />,
  },
  { path: "/management/special-needs-education", element: <SpecialNeeds /> },
  {
    path: "/management/early-childhood-care",
    element: <EarlyChildhoodCare />,
  },
  {
    path: "/management/ict-multimedia",
    element: <IctDepartment />,
  },
  {
    path: "/management/manager-academic",
    element: <ManagerAcademic />,
  },
  {
    path: "/management/pedagogy-and-study-practice",
    element: <Pedagogy />,
  },
  {
    path: "/management/science-technology-equipment",
    element: <ScienceTechEquipment />,
  },
  {
    path: "/management/print-production",
    element: <PrintProduction />,
  },
  {
    path: "/management/document-library-services",
    element: <Documentation />,
  },
  {
    path: "/management/BTVET-lifelong",
    element: <LifeSkill />,
  },
  {
    path: "/management/:slug",
    element: <Directorate />,
  },
  {
    path: "/careers",
    element: <Careers />,
  },
  {
    path: "/careers/:jobId",
    element: <Career />,
  },
  {
    path: "/resources",
    element: <Resources />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/events-and-calendar",
    element: <Calendar />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/services/:slug",
    element: <Services />,
  },
  {
    path: "/services/publication-evaluation/approved-books",
    element: <TableContent />,
  },
  {
    path: "/tenders-and-bids",
    element: <TendersBids />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/faqs",
    element: <FAQs />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/search/q/:q",
    element: <Search />,
  },
  {
    path: "/news",
    element: <Posts />,
  },
  {
    path: "/news/:slug",
    element: <Post />,
  },
  {
    path: "/notices/:slug",
    element: <Notice />,
  },
  {
    path: "/adverts/:slug",
    element: <Advert />,
  },
  {
    path: "/media-and-press",
    element: <MediaPress />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/cart/checkout",
    element: <Checkout />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
