import MaleAvatar from "assets/images/man-avatar.jpeg";
import { UPLOADS_URL } from "config";
import { useManagers } from "../api/getManagers";

export function Government() {
  const membersQuery = useManagers();
  // if (membersQuery.isLoading) {
  //   return (
  //     <div className="w-full h-48 flex justify-center items-center">
  //       <Spinner size="lg" />
  //     </div>
  //   );
  // }

  if (!membersQuery.data) return null;

  return (
    <>
      <div className="text-center mt-10">
        {membersQuery.data.map((member, index) => {
          if (member.designation.slug === "chairperson")
            return (
              <div className="flex flex-col my-4">
                <div className="text-center">
                  <div className="">
                    <img
                      src={
                        UPLOADS_URL + "/" + member.frontViewAvatar || MaleAvatar
                      }
                      alt={member.firstName}
                      className="rounded-full mx-auto h-28 w-28 my-2 border-2 border-green-500"
                    />
                  </div>
                  <div className="py-2 mx-2">
                    <p className="font-bold text-xl">
                      {member.firstName} {member.lastName}
                    </p>
                    <p>{member.designation?.title}</p>
                  </div>
                </div>
              </div>
            );
        })}
      </div>
      <div className="grid grid-row md:grid-cols-3 lg:grid-cols-4 pt-4">
        {membersQuery.data.map((member, index) => (
          <div key={index} className="flex flex-col my-4">
            <div className="text-center">
              <div className="">
                <img
                  src={UPLOADS_URL + "/" + member.frontViewAvatar || MaleAvatar}
                  alt={member.firstName}
                  className="rounded-full mx-auto h-28 w-28 my-2 border-2 border-green-500"
                />
              </div>
              <div className="py-2 mx-2">
                <p className="font-bold text-xl">
                  {member.firstName} {member.lastName}
                </p>
                <p>{member.designation?.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
