import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "components/elements";
import { MainLayout, PageHeader } from "components/layout";
import { useJobs } from "../api/getJobs";
import { useCareersDetails } from "../api/getPageDetails";
import { UPLOADS_URL } from "config";

export function Careers() {
  const jobsQuery = useJobs();

  const detailsQuery = useCareersDetails();

  console.log(detailsQuery.data);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (jobsQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  console.log(jobsQuery.data);

  if (!jobsQuery.data) return null;

  return (
    <MainLayout page="opportunity">
      <PageHeader
        header={detailsQuery.data?.title || "Undefined"}
        desc={detailsQuery.data?.desc}
        backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
      />
      <div className="flex flex-col mx-8 lg:mx-20 my-6 md:my-16">
        {!jobsQuery.data.length && (
          <div className="w-full my-12">
            <p className="my-4 w-full text-green-800">
              We currently have no available positions.
              <br />
            </p>
          </div>
        )}
        {jobsQuery.data && (
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {jobsQuery.data.map((job, index) => (
              <div key={index}>
                <div className="rounded-xl h-48 bg-green-600 text-center py-16">
                  <p className="text-white py-4">{job.title}</p>
                  <button className="bg-white rounded-full px-4 py-2 mx-auto">
                    <Link to={`/careers/${job._id}`}>Apply now</Link>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </MainLayout>
  );
}
