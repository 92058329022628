import React, { useEffect } from "react";
import { MainLayout, PageHeader } from "components/layout";
import DefaultAvatar from "assets/images/man-avatar.jpeg";

export const PrintProduction = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout page="management">
      <PageHeader
        header="Print & Production"
        to="/management"
        parent="Management"
      />

      <div className="mx-8 lg:mx-20 my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-gray-500 col-span-3 text-justify">
            <p className="font-semibold my-2">Print and Production</p>
            <ul className="my-2 list-disc">
              <li>
                Ensures efficient and effective administration and management of
                resources in printing and production processes
              </li>
              <li>
                Ensures proper maintenance of production facilities to sustain
                steady production
              </li>
              <li>Mobilizes resources and generates income for the Centre</li>
            </ul>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={DefaultAvatar}
              alt="avatar"
              className="h-60 w-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2 text-center">
              <>Name</>
            </p>
            <p className="text-center">Title</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
