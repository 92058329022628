import clsx from "clsx";

import { FieldWrapper } from "./FieldWrapper";

export const SearchField = (props) => {
  const { className, disabled, value, registration, onChange, error } = props;
  return (
    <FieldWrapper error={error}>
      <input
        type="search"
        className={clsx("focus:outline-none", className)}
        disabled={disabled}
        placeholder="Search..."
        value={value}
        {...registration}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};
