import clsx from "clsx";

import { FieldWrapper } from "./FieldWrapper";

export const InputField = (props) => {
  const {
    type = "text",
    label,
    className,
    placeholder,
    disabled,
    value,
    registration,
    onChange,
    error,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <input
        type={type}
        className={clsx(
          "bg-gray-100 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:text-xs:text-xs",
          className
        )}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        {...registration}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};
