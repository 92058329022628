import React from "react";
import { usePosts } from "../api/getPosts";
// import { ArrowRight } from "react-feather";
import { CarouselItem } from "./CarouselItem";
// import formatDate from "utils/formatDate";
import { formatHTMLToFirstSentence } from "utils/formatHTMLToFirstSentence";
import { UPLOADS_URL } from "config";
import Skeleton from "react-loading-skeleton";
import { Text } from "components/elements";

export const NewsEvents = () => {
  const postsQuery = usePosts();

  if (postsQuery.isLoading) {
    return (
      <div className="m-2">
        <div className="flex justify-between">
          <h1 className="text-xl font-bold">
            <Skeleton width={200} />
          </h1>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-2 h-full">
          <Skeleton height={350} width={350} />
        </div>
      </div>
    );
  }

  if (!postsQuery.data)
    return (
      <div>
        <Text size="lg">Unable to fetch content</Text>
      </div>
    );

  return (
    <div>
      <div className="mx-2">
        <div className="flex justify-between">
          <h1 className="font-bold">
            <p className="text-xl">News & Events</p>
          </h1>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 h-full">
          {postsQuery.data.slice(0, 2).map((post, index) => {
            return (
              <CarouselItem
                key={index}
                title={post.title}
                src={`${UPLOADS_URL}/${post.image}`}
                desc={
                  formatHTMLToFirstSentence(post.desc)
                    .split(" ")
                    .slice(0, 17)
                    .join(" ") + "..."
                }
                to={`/news/${post.slug}`}
                // time={formatDate(post.createdAt)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
