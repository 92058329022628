import { axios } from '../../../lib/axios';
import { useQuery } from 'react-query';

export const getFaqs = () => {
  return axios.get('/faqs');
};

export const useFaqs = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['faqs'],
    queryFn: () => getFaqs(),
  });
};
