import PropTypes from "prop-types";

export const Text = (props) => {
  const { children, size = "md" } = props;
  return <label className={`text-${size}`}>{children}</label>;
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  size: PropTypes.string,
};
