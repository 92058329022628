import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getServicesDetails = () => {
  return axios.get(`/pages/slug/services`);
};

export const useServicesDetails = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["servicesDetails"],
    queryFn: () => getServicesDetails(),
  });
};
