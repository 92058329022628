import React from "react";
import { JoinMailingList } from "../contact/components/JoinMailingList";

export const Newsletter = () => {
  return (
    <div>
        <JoinMailingList />
    </div>
  );
};
