/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { Download, Loader, ShoppingCart } from "react-feather";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";

import { PageHeader, ShopLayout } from "components/layout";
import { useResources } from "../api/getResources";
import { UPLOADS_URL } from "config";
import { Search } from "react-feather";
import { NewPagination } from "components/elements/Pagination/Newpagination";
import { useDownloadResource } from "../api/downloadResource";
import { useResourcesDetails } from "../api/getPageDetails";
import Lazyimage from "assets/images/lazyimage.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const curriculumsQuery = [
  { label: "ECCE Curriculum", value: "ECCE Curriculum" },
  { label: "Primary Curriculum", value: "Primary Curriculum" },
  { label: "Secondary Curriculum", value: "Secondary Curriculum" },
  { label: "BTVET Curriculum", value: "BTVET Curriculum" },
];

const subCurriculums = [
  [
    {
      label: "Early Childhood Development",
      value: "Early Childhood Development",
    },
    {
      label: "Learning Framework for Early Childhood",
      value: "Learning Framework for Early Childhood",
    },
    {
      label: "Caregivers Guide to the Learning Framework",
      value: "Caregivers Guide to the Learning Framework",
    },
  ],
  [
    { label: "Upper Primary Curriculum", value: "Upper Primary Curriculum" },
    { label: "Transition Curriculum", value: "Transition Curriculum" },
    {
      label: "Thematic / Lower Primary Curriculum",
      value: "Thematic / Lower Primary Curriculum",
    },
    { label: "Primary School Curriculum", value: "Primary School Curriculum" },
  ],
  [
    {
      label: "O Level Curriculum",
      value: "O Level Curriculum",
    },
    { label: "A Level Curriculum", value: "A Level Curriculum" },
  ],
  [
    { label: "BTVET Curriculum", value: "BTVET Curriculum" },
    { label: "Certificate Courses", value: "Certificate Courses" },
    { label: "Diploma Programs", value: "Diploma Programs" },
  ],
];

export function Resources() {
  const detailsQuery = useResourcesDetails();

  const resourcesQuery = useResources(); //get all resources from dB
  const navigate = useNavigate();

  const [activeBtn, setActiveBtn] = useState(0); //state for active btn in subCur
  const downloadResourceMutation = useDownloadResource();

  const [maxPageNumber, setmaxPageNumber] = useState(5); //state for page posts in pagination
  const [minPageNumber, setminPageNumber] = useState(1);

  const [posts, setPosts] = useState([]); //state for posts per curriculum

  const [currentPage, setCurrentPage] = useState(1); //state for current page displaying posts
  const postsPerPage = 10;

  useEffect(() => {
    if (resourcesQuery.data) {
      setCurrentPage(1);
      setPosts(resourcesQuery.data);
    }
  }, [resourcesQuery.data]);

  //get items depending on category
  var curriculumItems = posts.filter((post) => {
    if (curriculumsQuery[activeBtn]) {
      return curriculumsQuery[activeBtn].value === post.curriculum;
    }
    return post;
  });

  //scroll to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  //add product to cart
  const onAdd = (product) => {
    const cartItems = JSON.parse(localStorage.getItem("cart"));
    const exist = cartItems.find((x) => x._id === product._id);
    if (exist) {
      const newCart = cartItems.map((x) => {
        if (x._id === product._id) {
          x.quantity += 1;
        }
        return x;
      });
      localStorage.setItem("cart", JSON.stringify(newCart));
    } else {
      cartItems.push({ ...product, quantity: 1 });
      localStorage.setItem("cart", JSON.stringify(cartItems));
    }
    navigate("/cart");
  };

  //State for modal and search feature
  const [download, setDownload] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  //state for radio button (subCurriculums)
  const [radioActive, setRadioActive] = useState(" ");
  const handleRadioChange = (event) => {
    setRadioActive(event.target.value);
  };

  //resources loader
  if (resourcesQuery.isLoading)
    return (
      <div className="w-full h-[100vh] bg-slate-200 flex items-center">
        <div className="text-center mx-auto flex flex-row space-x-1">
          <span>Organizing Resources</span>
          <Loader className="text-gray-400" />
        </div>
      </div>
    );

  //check if resources are available
  if (!resourcesQuery.data)
    return (
      <div className="w-full h-[100vh] bg-slate-200 flex items-center">
        <div className="text-center mx-auto flex flex-row space-x-1">
          <span>No resources available at the moment.</span>
        </div>
      </div>
    );

  //Created a list for Filtered resources and pass it for mapping
  var itemList = curriculumItems.filter((item) => {
    if (searchValue) {
      return item.title.toLowerCase().includes(searchValue.toLowerCase());
    }
    if (radioActive) {
      return (
        item.subCurriculum?.includes(radioActive) ||
        item.subjectOrCourse?.includes(radioActive)
      );
    }
    if (curriculumsQuery[activeBtn]) {
      return curriculumsQuery[activeBtn].value === item.curriculum;
    }
    return item;
  });

  // Get posts for displaying on page from filtered posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = itemList.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumberLimit = 5; //number of counters for pagination (1,2,3,4....6,7)

  // Change page for pagination
  const paginateFront = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumber) {
      setmaxPageNumber(maxPageNumber + pageNumberLimit);
      setminPageNumber(minPageNumber + pageNumberLimit);
    }
  };
  const paginateBack = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumber(maxPageNumber - pageNumberLimit);
      setminPageNumber(minPageNumber - pageNumberLimit);
    }
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <ShopLayout page="resources">
      <PageHeader
        header={detailsQuery.data?.title || "Undefined"}
        desc={detailsQuery.data?.desc}
        backgroundImage={`${UPLOADS_URL}/${detailsQuery.data?.pageHeaderBgImage}`}
      />
      <div className="mx-8 lg:mx-20">
        <div className="flex flex-row items-center lg:hidden">
          <input
            type="text"
            name="search"
            className="px-3 py-3 text-base font-normal rounded w-full focus:outline-none"
            value={searchValue}
            placeholder="Search for resources..."
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <Search className="mx-3" />
        </div>
        <div>
          {/* Drop down */}
          <div className="flex lg:hidden justify-center w-md py-4 my-4">
            <div>
              <div className="dropdown relative w-72">
                <a
                  className="dropdown-toggle p-4 text-black font-semibold text-lg leading-tight rounded 
                  shadow-md transition duration-150 ease-in-out flex items-center justify-between"
                  href="/"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {curriculumsQuery[activeBtn].value}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    className="w-2 ml-2"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                </a>
                <ul
                  className="dropdown-menu min-w-full absolute bg-white text-base z-50 
                   py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding 
                   border-none hidden"
                  aria-labelledby="dropdownMenuButton2"
                >
                  {curriculumsQuery.map((name, index) => (
                    <li
                      key={index}
                      className={
                        activeBtn === index
                          ? "dropdown-item block bg-transparent p-4 font-semibold border-b-2 border-green-400 text-green-500"
                          : "dropdown-item block bg-transparent p-4 font-semibold hover:text-green-500"
                      }
                      onClick={() => setActiveBtn(index)}
                    >
                      {name.value}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* MainMenu */}
          <div className="hidden lg:flex justify-between ">
            <div className="pt-4 flex flex-row space-x-2 my-4">
              {curriculumsQuery.map((name, index) => (
                <button
                  key={index}
                  className={
                    activeBtn === index
                      ? "px-4 py-2 border border-gray-200 bg-green-500 font-semibold text-white"
                      : "px-4 py-2 text-green-500"
                  }
                  onClick={() => {
                    setActiveBtn(index);
                    setRadioActive(null);
                    setCurrentPage(1);
                  }}
                >
                  {name.value}
                </button>
              ))}
            </div>
            <div className="flex flex-row items-center">
              <input
                type="text"
                name="search"
                className="my-2 w-full border border-gray-300 px-3 py-3 text-base font-normal rounded focus:outline-none"
                value={searchValue}
                placeholder="Search for resources..."
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              {/* <Search className="mx-3" /> */}
            </div>
          </div>

          {/* Section for subcurriculums */}
          <div
            className="px-2 mb-4 py-3 flex flex-col md:flex-row items-center justify-between
           border border-gray-200 rounded-sm"
          >
            <div className="flex flex-col md:flex-row">
              {subCurriculums[activeBtn].map((subCurriculum, index) => (
                <div key={index} className="flex flex-col md:flex-row">
                  <input
                    type="radio"
                    id={index}
                    name={subCurriculum.value}
                    value={subCurriculum.value}
                    checked={radioActive === subCurriculum.value}
                    onChange={handleRadioChange}
                    className="mx-2 my-4 md:my-0 lg:mx-4"
                    style={{ transform: "scale(1)" }}
                  />
                  <p className="text-md">{subCurriculum.value}</p>
                </div>
              ))}
            </div>
            <button
              className=" m-2 px-2 py-3 bg-green-500 hover:bg-green-300 text-white font-semibold"
              onClick={() => setRadioActive(null)}
            >
              Reset filter
            </button>
          </div>
        </div>

        {/* section for displaying Books */}
        <div className="flex">
          <div
            className="flex flex-col h-10/12 gap-4 md:grid md:grid-cols-3 lg:grid-cols-5 
          md:gap-8 bg-white"
          >
            {currentPosts.map((bk, index) => (
              <div key={index}>
                <div className="border border-gray-100 rounded-sm h-[265px]">
                  <LazyLoadImage
                    src={`${UPLOADS_URL}/${bk.cover}`}
                    className="w-full h-[265px]"
                    alt={bk.title}
                    placeholderSrc={Lazyimage}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="hidden text-sm py-1 px-2 w-100">
                    {bk.title}
                  </div>

                  {bk.price === 0 || bk.price === null ? (
                    <a
                      href="#"
                      onClick={async (e) => {
                        e.preventDefault();
                        setDownload(`${UPLOADS_URL}/${bk.resource}`);
                        await downloadResourceMutation.mutateAsync({
                          resourceId: bk.id,
                        });
                        // setModal(true);
                        saveAs(download, `${bk.slug}.pdf`);
                      }}
                      className="flex flex-row mx-2 justify-center text-xs items-center space-x-2 px-4 py-2 my-2 rounded-lg text-green-500 hover:bg-green-600 hover:text-white"
                    >
                      <span>Free Download</span>{" "}
                      <Download className="mx-auto h-4" />
                    </a>
                  ) : (
                    <button
                      onClick={() => onAdd(bk)}
                      className="flex flex-row justify-center mx-2 text-xs items-center space-x-2 px-4 py-2 my-2 rounded-lg text-green-500 hover:bg-green-600 hover:text-white"
                    >
                      <span>Add to Cart</span>{" "}
                      <ShoppingCart className="mx-auto h-4" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {itemList.length === 0 && (
          <div className="">
            <p className="text-xl text-center font-bold my-4">
              No Books available
            </p>
          </div>
        )}
        <div className="flex flex-row justify-center mt-10">
          <NewPagination
            postsPerPage={postsPerPage}
            totalPosts={itemList.length}
            paginate={paginate}
            currentPage={currentPage}
            paginateFront={paginateFront}
            paginateBack={paginateBack}
            maxPageNumber={maxPageNumber}
            minPageNumber={minPageNumber}
          />
        </div>
      </div>
    </ShopLayout>
  );
}
