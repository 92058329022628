import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useMutation } from "react-query";
import { useNotificationStore } from "stores/notifications";

export const searchFor = (q) => {
  return axios.get(`/search?q=${q}`);
};

export const useSearchFor = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (newSearchParam) => {
      await queryClient.cancelQueries("searches");

      const previousSearches = queryClient.getQueryData("searches");

      queryClient.setQueryData("searches", [
        ...(previousSearches || []),
        newSearchParam.q,
      ]);

      return { previousSearches };
    },
    ...config,
    mutationFn: searchFor,
    onError: (err) => {
      addNotification({
        type: "error",
        message: err.response.data.msg,
      });
    },
  });
};
