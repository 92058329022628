import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getAbout = () => {
  return axios.get(`/about`);
};

export const useAbout = () => {
  return useQuery({
    queryKey: ["about"],
    queryFn: () => getAbout(),
  });
};
