/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { PageHeader, ShopLayout } from "../../../components/layout";
import { APP_URL } from "../../../config";

import VisaIcon from "../../../assets/images/cards/visa.png";
import MTNIcon from "../../../assets/images/cards/mtn.png";
import AirtelIcon from "../../../assets/images/cards/airtel.png";

export function Checkout() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [organization, setOrganization] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const amount = JSON.parse(localStorage.getItem("cartTotal"));

  const config = {
    public_key: "FLWPUBK-0ec66322eb07e3d44a00138cd18a1567-X",
    tx_ref: Date.now(),
    amount: amount,
    currency: "UGX",
    country: "UG",
    payment_options:
      "card,account,banktransfer,mpesa,mobilemoneyuganda,ussd,credit",
    redirect_url: `${APP_URL}/payment/callback`,
    customer: {
      email: email,
      phonenumber: phone,
      name: name,
    },
    customizations: {
      title: "Payment for your order",
      description: "Payments have been made for your order",
      logo: "https://ncdc-admin.pages.dev/favicon.ico",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <ShopLayout>
      <PageHeader header="Checkout" parent="Cart" to="/cart" />
      <form>
        <div className="w-5/6 md:w-3/4 lg:w-1/2 mx-auto my-12 grid grid-cols-1 p-12 border border-gray-300 rounded-lg">
          <div className="">
            <div className="flex flex-col items-center">
              <p className="text-2xl font-semibold">Amount to Pay</p>
              <p className="text-xl font-semibold bg-green-100 p-4 px-8 mt-2">
                {amount}
                <span className="text-sm"> UGX</span>
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center my-4 space-x-4">
            <img src={VisaIcon} alt="Visa" className="w-auto h-6" />
            <img src={MTNIcon} alt="Visa" className="w-auto h-8" />
            <img src={AirtelIcon} alt="Visa" className="w-auto h-8" />
          </div>

          <div className="mx-auto my-4 text-gray-400">
            We require your details to complete the payment
          </div>
          <input
            type="text"
            placeholder="Name"
            name="name"
            className="border border-black pl-4 py-2 rounded-lg mx-4"
            onChange={(e) => setName(e.target.value)}
          ></input>
          <input
            type="text"
            name="organization"
            placeholder="Organization"
            className="border border-black px-4 py-2 rounded-lg m-4"
            onChange={(e) => setOrganization(e.target.value)}
          ></input>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="email"
              placeholder="Email"
              name="email"
              className="border border-black px-4 py-2 rounded-lg m-4"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <input
              type="text"
              placeholder="07*********"
              name="phone"
              className="border border-black px-4 py-2 rounded-lg m-4"
              onChange={(e) => setPhone(e.target.value)}
            ></input>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleFlutterPayment({
                callback: (response) => {
                  console.log(response);
                  closePaymentModal(); // this will close the modal programmatically
                },
                onClose: () => {},
              });
            }}
            className="p-4 bg-green-600 m-4 rounded-lg hover:bg-green-600 text-white"
          >
            Make Payment
          </button>
          <Link
            to="/cart"
            className="mx-10 hover:text-green-800 hover:font-semibold"
          >
            Return to Cart
          </Link>
        </div>
      </form>
    </ShopLayout>
  );
}
