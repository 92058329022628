
import { useRoutes } from 'react-router-dom';
import { Home } from '../features/home';

import { publicRoutes } from './public';

export const AppRoutes = () => {

  const commonRoutes = [{ path: '/', element: <Home /> }];

  const element = useRoutes([...publicRoutes, ...commonRoutes]);

  return <>{element}</>;
};
